<template>
    <portal to="modals">
        <div class="modal-overlay">
            <div class="modal-content" ref="ele">
                <div class="flex-row space-between">
                    <h2 class="inlne-flex m-0 mb-1">{{ title }}</h2>
                    <button class="modal-close" aria-label="close modal" @click="handleCloseModal"><i
                            class="fa fa-close text-color-2"></i></button>
                </div>
                <span class="modal-subtitle text-color-2 mb-3 block">{{ subtitle }}</span>
                <router-view name="default" v-slot="{ Component }" @update-subtitle="onUpdateSubtitle"
                    @update-title="onUpdateTitle">
                    <Component />
                </router-view>
            </div>
        </div>
    </portal>
</template>
<script setup>
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router/composables';
import { useSetupStore } from '../../js/store';

const route = useRoute();
const setupStore = useSetupStore();

const title = ref(setupStore.title);
const subtitle = ref('');
const ele = ref(null);

const onUpdateTitle = function (newTitle) {
    if (newTitle)
        title.value = newTitle;
};
const onUpdateSubtitle = function (newSubtitle) {
    subtitle.value = newSubtitle;
};

watch(route, () => {
    ele.value.scroll({ top: 0, behavior: 'smooth' });
});

const handleCloseModal = () => {
    setupStore.closeModal();
};

</script>