<template>
    <div>
        <div v-observe-visibility="{
            callback: displayChart,
            once: true,
        }" :style="{ height: height, display: 'flex', flexDirection: 'column', overflow: 'hidden' }" width="100%">
            <ejs-chart background="#f3f5f8" ref="chartJS" :height="height" :id="chartKey + sensor?.ID"
                :key="chartKey + sensor?.ID" width="100%" :primaryXAxis="primaryXAxis" :tooltip="tooltip"
                :crosshair="crosshair" :axes="axes" :palettes="['#d8d2f8']" :tooltipRender="tooltipRender"
                :chartMouseLeave="beforeTooltipClose" :legendSettings="{ visible: false }" v-if="display">
                <e-series-collection>
                    <e-series :dataSource="chartData" type="SplineArea" xName="chartDate" yName="snowDepth"
                        name="snowDepth" yAxisName="yAxisSnowDepth" tooltipMappingName="chartDate"
                        :emptyPointSettings="{ mode: 'Average' }" />
                </e-series-collection>
            </ejs-chart>
        </div>
        <fixed-tooltip :highlighted="tooltipValues" :items="items" />
    </div>
</template>

<script>
import {
    Tooltip,
    DateTime,
    Logarithmic,
    Crosshair,
    SplineSeries,
    StripLine,
    AreaSeries,
    SplineAreaSeries,
    ScatterSeries,
} from "@syncfusion/ej2-vue-charts";
import { mapStores } from "pinia";
import moment from "moment-timezone";

import FixedTooltip from "./fixedTooltip.vue";

import { useBaseStore } from "../js/store";

export default {
    name: "sensor-chart",
    props: [
        'chartKey',
        "sensor",
        "height",
        "dataTransmissions",
    ],
    components: {
        FixedTooltip,
    },
    provide: {
        chart: [
            Tooltip,
            DateTime,
            StripLine,
            SplineSeries,
            SplineAreaSeries,
            Logarithmic,
            Crosshair,
            AreaSeries,
            ScatterSeries,
        ],
    },
    data: function () {
        return {
            crosshair: { enable: true, lineType: "Vertical", line: { width: 0.5, color: "#5fb1f6" } },
            tooltip: {
                enable: true,
                shared: true,
                enableAnimation: false,
                header: "${point.tooltip}",
            },
            display: false,
            items: [
                {
                    title: "Snow Depth",
                    key: "snowDepth",
                    abbr: "Depth",
                    unit: 'units',
                }
            ],
            tooltipDate: "",
        };
    },
    methods: {
        beforeTooltipClose() {
            this.tooltipDate = "";
        },
        tooltipRender(args) {
            this.tooltipDate = args.headerText ? args.headerText : args.point.tooltip;
            // Hide tooltip but keep crosshair
            args.cancel = true;
        },
        displayChart(isVisible) {
            if (isVisible) {
                this.display = true;
            }
        },
    },
    computed: {
        ...mapStores(useBaseStore),
        tooltipValues() {
            // It should return the now data which is essential the most recent data
            const chartDate = this.tooltipDate
            if (chartDate === '') return
            const values = this.chartData.filter((d) => moment(d.chartDate).isSame(chartDate, 'minute'))[0];

            return values;
        },
        timeZone() {
            return this.sensor.TimeZone;
        },
        isMetric() {
            return this.sensor?.GroupEmbedded?.IsMetric ? true : false;
        },
        primaryXAxis() {
            return {
                visible: false,
                color: "#5fb1f6",
                valueType: "DateTime",
                labelFormat: "h a",
                intervalType: "Hours",
                labelStyle: {
                    size: "12px",
                },
                title: false,
                crosshairTooltip: { enable: false },
                majorGridLines: { color: "transparent" },
                majorTickLines: { color: "transparent" },
            };
        },
        axes() {
            const maxValue = Math.max(...this.chartData.map((d) => d.snowDepth));
            let interval = 10;
            if (this.isMetric) {
                if (maxValue > 1000) {
                    interval = 500;
                } else if (maxValue > 100) {
                    interval = 50;
                } else {
                    interval = 10;
                }
            } else {
                if (maxValue > 40) {
                    interval = 24;
                } else if (maxValue > 10) {
                    interval = 12;
                } else {
                    interval = 4;
                }
            }

            return [
                {
                    name: "yAxisSnowDepth",
                    labelFormat: "{value}" + (this.isMetric ? " mm" : " in"),
                    labelStyle: {
                        size: "12px",
                    },
                    minimum: 0,
                    interval,
                    majorGridLines: { color: "#e9e9e9" },
                    majorTickLines: { color: "#e9e9e9" },
                },
            ];
        },
        chartData() {
            return this.dataTransmissions.map((d) => {
                return {
                    chartDate: d.chartDate,
                    snowDepth: this.isMetric ? (d.SnowDepthReading.MeasuredHeightMm) : d.SnowDepthReading.MeasuredHeightInches,
                    units: this.isMetric ? "mm" : "in",
                };
            });
        },
    },
    watch: {
        chartData: {
            handler() {
                this.findNowValue()
            },
            deep: true,
        },
    },
    mounted() {
        // HACK: This is the only way I have been able to find to get the chart to resize
        // after any shifting of the page on load. This can be removed when we change the
        // chart library or figure out how to resize the chart properly.
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 2000);
    },
};
</script>
