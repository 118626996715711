<template>
    <div>
        <div v-if="currentStep.displayVideo === true">
            <span class="bold">Setup Video</span>
            <div class="embed-responsive embed-responsive-16by9">
                <iframe title="Setup video" class="embed-responsive-item" :src="embedVideoUrl" allowfullscreen></iframe>
            </div>
            <hr class="mb-3 mt-3">
        </div>
        <template v-for="(content, index) in currentStep.content">
            <step-content :content="content" :step-index="index" :deviceType="props.deviceType" :id="props.id"
                :step="props.step"></step-content>
        </template>
        <template v-if="currentStep.component">
            <component :is="currentStep.component" :deviceType="props.deviceType" :id="props.id" :step="props.step">
            </component>
        </template>
        <div v-if="currentStep.hideFooter !== true" class="modal-footer">
            <a v-if="videoUrl" :href="videoUrl" target="_blank" class="btn btn-outline-secondary mr-auto">
                <i class="fa fa-video"></i>
                Setup Video</a>
            <button class="btn btn-secondary mr-2" @click="goBack">
                <i class="fa" :class="{
                    'fa-close': isFirstStep,
                    'fa-arrow-left': !isFirstStep
                }"></i>
                {{ isFirstStep ? 'Cancel' : 'Back' }}</button>
            <button class="btn btn-primary" @click="next">
                <i class="fa" :class="{
                    'fa-arrow-right': !isLastStep,
                    'fa-save': isLastStep,
                }"></i>
                {{ isLastStep ? 'Finalize' : 'Continue' }}</button>
        </div>
    </div>
</template>

<script setup>
import { onMounted, defineEmits, onUpdated, computed } from 'vue';

import stepContent from './stepContent.vue';
import { useSetupStore } from '../../js/store';

const setupStore = useSetupStore();

const props = defineProps({
    deviceType: String,
    id: String,
    step: Number,
})

const currentStep = computed(() => setupStore.currentStep)
const isFirstStep = computed(() => setupStore.isFirstStep)
const isLastStep = computed(() => setupStore.isLastStep)
const videoUrl = computed(() => setupStore.videoUrl)
const embedVideoUrl = computed(() => videoUrl.value ? videoUrl.value.replace("youtu.be", "www.youtube.com/embed") : '')

const emit = defineEmits([
    'update-title',
    'update-subtitle',
])

onMounted(() => {
    emit('update-title', currentStep.value.title)
    emit('update-subtitle', currentStep.value.subtitle)
    // Verify that we have a device in the store
    if (!setupStore.device) {
        setupStore.getDeviceByVendorId(props.id)
    }
})

onUpdated(() => {
    emit('update-title', currentStep.value.title)
    emit('update-subtitle', currentStep.value.subtitle)
})

const goBack = () => {
    setupStore.goToPreviousStep()
};

const next = () => {
    setupStore.goToNextStep()
};

</script>
