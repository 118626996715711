<template>
    <div class="dm-page-container dm-flex-column dm-padding-md dm-height-100">
        <div class="app-settings-page-header dm-justify-content-flex-end dm-padding-bottom-sm dm-align-items-center">
            <form @submit="searchOnClick"
                class="app-settings-search-container dm-flex-row dm-align-items-center dm-flex-1">
                <div style="max-width: 300px;">
                    <ejs-textbox v-model="searchText" floatLabelType="Auto" placeholder="Search"
                        :showClearButton="true" />
                </div>

                <div class="dm-margin-left-sm">
                    <ejs-checkbox ref="includeInactive" v-model="includeInactive" label="Include Inactive"
                        :checked="includeInactive" />
                </div>

                <ejs-button type="submit" class="dm-margin-left-md dm-flex-none">
                    <span class="fa fa-search"></span> <span class="dm-margin-left-sm app-mobile-hide">Search</span>
                </ejs-button>
            </form>
            <div class="flex mr-2">
                <RouterLink to="/setup/add-device/scan" class="no-underline">
                    <div class="btn btn-secondary">
                        <span class="fa fa-plus"></span> <span class="dm-margin-left-sm app-mobile-hide">Add
                            Device</span>
                    </div>
                </RouterLink>
            </div>

            <div class="app-settings-navigation-container dm-flex-row dm-align-items-center">
                <div class="dm-flex-none e-tab">
                    <div class="e-tab-header e-control e-toolbar e-lib e-keyboard">
                        <div class="e-items e-toolbar-items">
                            <div @click="handleNavigation('groups')" class="e-item e-toolbar-item dm-flex-column"
                                :class="{
                                    'app-underline-tab': $route.name === 'groups',
                                    'e-active': $route.name === 'groups'
                                }">
                                <div class="e-tab-wrap">
                                    <div class="e-text-wrap">
                                        <span class="e-tab-text" role="presentation">Groups</span>
                                    </div>
                                </div>
                            </div>
                            <div @click="handleNavigation('locations')" class="e-item e-toolbar-item app-location-tab"
                                :class="{
                                    'app-underline-tab': $route.name === 'locations',
                                    'e-active': $route.name === 'locations'
                                }">
                                <div class="e-tab-wrap">
                                    <div class="e-text-wrap">
                                        <span class="e-tab-text" role="presentation">Locations</span>
                                    </div>
                                </div>
                            </div>

                            <div @click="handleNavigation('users')" class="e-item e-toolbar-item dm-flex-column" :class="{
                                'app-underline-tab': $route.name === 'users',
                                'e-active': $route.name === 'users'
                            }">
                                <div class="e-tab-wrap">
                                    <div class="e-text-wrap">
                                        <span class="e-tab-text" role="presentation">Users</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="dm-margin-left-md">
                    <ejs-button v-if="$route.name === 'users'" @click.native="handleOpenModalClick('openAddUser')"
                        is-primary="true" cssClass="app-setting-page-add-item-btn blue-gradient">
                        <span class="fa fa-plus-square"></span> <span class="dm-margin-left-sm app-mobile-hide">Add
                            User</span>
                    </ejs-button>

                    <ejs-button v-if="$route.name === 'groups' && isUserSuperAdmin"
                        @click.native="handleOpenModalClick('openAddGroup')" is-primary="true"
                        cssClass="app-setting-page-add-item-btn blue-gradient">
                        <span class="fa fa-plus-square"></span> <span class="dm-margin-left-sm app-mobile-hide">Add
                            Group</span>
                    </ejs-button>

                </div>
            </div>
        </div>

        <div class="dm-flex-column dm-flex-grow dm-overflow-hidden">
            <router-view />
        </div>
        <router-view name="modal" />
    </div>

</template>

<script>
import { mapStores } from "pinia";
import { useBaseStore } from "../../js/store";
import { RouterLink } from "vue-router";

export default {
    name: "settings-page",
    props: {
        id: {
            type: String,
            default: null,
        },
        deviceType: {
            type: String,
            default: null,
        },
    },
    data: function () {
        return {
            searchText: "",
            includeInactive: false,
        };
    },
    methods: {
        handleNavigation(name) {
            if (this.$router.name !== name) {
                this.$router.push({ name });
            }
        },
        handleOpenModalClick(event) {
            eventBus.$emit(event);
        },
        searchOnClick(e) {
            e.preventDefault();
            eventBus.$emit("searchItems", {
                search: this.searchText,
                includeInactive: this.includeInactive,
            });
        },
    },
    watch: {
        templateName: function (newVal) {
            this.searchText = "";
            this.includeInactive = false;
        },
    },
    computed: {
        ...mapStores(useBaseStore),
        isUserSuperAdmin() {
            return this.baseStore.user?.IsSuperAdmin;
        },
    },
    created() {
    },
};
</script>
