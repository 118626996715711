<template>
    <div>
        <QrcodeStream v-if="reset == false" @decode="onDecode" :paused="paused" @init="handleCameraReady"
            @error="onError">
            <div v-if="errorMessage !== null" class="need-permissions">
                <p>{{ errorMessage }}</p>
            </div>
            <div v-if="validationPending === true" class="validation-pending"></div>
            <div v-if="validationSuccessful === true" class="validation-successful">
                <p>Device Found</p>
            </div>
            <div v-if="validationSuccessful === false" class="validation-failed">
                <p>Invalid Code</p>
                <button class="btn btn-primary" @click="triggerReset">Try Again</button>
            </div>
        </QrcodeStream>
    </div>
</template>

<script>
import { ref } from 'vue';
import { QrcodeStream } from 'vue-qrcode-reader';

export default {
    name: 'QrCodeScanner',
    components: {
        QrcodeStream,
    },
    props: {
        handleSuccessfulDecode: {
            type: Function,
            required: true,
        },
        validationPending: {
            type: Boolean,
            required: true,
        },
        validationSuccessful: {
            type: Boolean | null,
            required: true,
        },
        handleReset: {
            type: Function,
            required: true,
        },
        paused: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            errorMessage: 'Camera permissions are required to scan a QR code',
            reset: false,
        };
    },
    methods: {
        onError(error) {
            console.log(error);
            if (error.name === 'NotAllowedError') {
                this.errorMessage = 'Camera permissions are required to scan a QR code';
            } else {
                this.errorMessage = 'An error occurred while trying to access the camera';
            }
        },
        handleCameraReady(e) {
            e.then(() => {
                this.errorMessage = null;
            }).catch(this.onError);
        },
        onDecode(decoded) {
            try {
                const url = new URL(decoded);
                const params = Object.fromEntries(url.searchParams);

                if (params.hasOwnProperty('id') && params.hasOwnProperty('producttype') && params.hasOwnProperty('type')) {
                    this.handleSuccessfulDecode(params);
                }
            } catch (error) {
                console.log('Invalid QR code format:', error);
            }
        },
        triggerReset() {
            this.reset = true;
            this.handleReset();
            this.$nextTick(() => {
                this.reset = false;
            });
        },
    },
};
</script>
