<template>
    <div>
        <div class="bg-lighter p-3">
            <h4 class="m-0">Sensor Status</h4>
            <sensor-status></sensor-status>
            <div v-if="errorMessage" class="alert alert-danger mt-3" role="alert">
                <i class="fa fa-exclamation-circle mr-1"></i>{{ errorMessage }}

            </div>
            <button class="btn btn-outline-secondary mt-3" @click="retestSensor">
                <i class="fa fa-refresh" :class="{
                    'fa-spin': loading
                }"></i>
                Retest Sensor</button>
        </div>
        <div class="modal-footer">
            <button class="btn btn-secondary mr-2" @click="handleBack">
                <i class="fa fa-close"></i>
                Back</button>
            <button class="btn btn-primary" :disabled="disabled" @click="next">
                <i class="fa fa-arrow-right"></i>
                Continue</button>
        </div>
    </div>
</template>
<script setup>
import { onMounted, defineProps, computed, ref } from 'vue';

import SensorStatus from './sensorStatus.vue';

import { useSetupStore } from '../../js/store';

const setupStore = useSetupStore();

const props = defineProps({
    deviceType: String,
    id: String,
    step: Number,
    routeAfterStep: String,
})

const loading = ref(false);

const errorMessage = computed(() => {
    if (setupStore.diagnostics.signalStrength <= 10) {
        return 'Signal strength is too low to continue. Contact support for assistance at 312-767-9423, option 0';
    }
    if (setupStore.diagnostics.signalStrength < setupStore.diagnostics.minSignalStrength) {
        return 'Signal strength is below recommended levels. You can continue but you may notice issues with your device.';
    }
    if (setupStore.diagnostics.errorMessage !== null) {
        return setupStore.diagnostics.errorMessage;
    }
    return null;
});

const id = computed(() => setupStore.setupDevice?.ID);

const retestSensor = async () => {
    loading.value = true;
    await setupStore.runDiagnostics(id.value);
    loading.value = false;
}

onMounted(async () => {
    loading.value = true;
    if (id.value) {
        await setupStore.runDiagnostics(id.value);
    } else {
        await setupStore.getDeviceByVendorId(props.id);
        await setupStore.runDiagnostics(setupStore.setupDevice.ID);
    }
    loading.value = false;
})

const handleBack = () => {
    setupStore.goToPreviousStep();
}

const next = () => {
    setupStore.goToNextStep();
}

const disabled = computed(() => {
    if (!setupStore.setupDevice || !setupStore.diagnostics) {
        return true;
    }
    if (setupStore.diagnostics.diagnosticStatus !== 'ok') {
        return true
    }
    if (setupStore.diagnostics.signalStrength < 10) {
        return true
    }
    return false
});
</script>