<template>
    <div>

        <div class="bg-lighter p-3">
            <h4 class="m-0">{{ displayName }}</h4>
            <sensor-status></sensor-status>
            <div class="relative mt-3">
                <image-with-overlay :src="latest.ProcessedCameraImageURL" alt="Device Photo"
                    :computer-vision="latest.MostRecentComputerVision"
                    :data-transmission="latest.MostRecentDataTransmission" :hide-readings="hideReadings"
                    class="img-fluid w-full"></image-with-overlay>
            </div>
            <div>
                <button class="btn btn-outline-secondary mt-3" @click="retakePhoto">
                    <i class="fa fa-camera"></i>
                    Retake Photo</button>
            </div>
            <div>
                <div v-if="errorMessage" class="alert alert-danger mt-3" role="alert">
                    <i class="fa fa-exclamation-circle mr-1"></i>{{ errorMessage }}
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-secondary mr-2" @click="handleBack">
                <i class="fa fa-close"></i>
                Back</button>
            <button class="btn btn-primary" :disabled="disabled" @click="next">
                <i class="fa fa-save"></i>
                Finalize</button>
        </div>
    </div>

</template>
<script setup>
import { defineProps, ref, onMounted, computed } from 'vue';
import { useSetupStore } from '../../js/store';
import imageWithOverlay from '../imageWithOverlay.vue';
import sensorStatus from './sensorStatus.vue';
import API from '../../js/api';

const setupStore = useSetupStore();

const errorMessage = ref(null);

const props = defineProps({
    id: String,
    deviceType: String,
    step: Number,
});

const deviceName = {
    RWIS_V1: 'Mini RWIS',
    FVC_12: 'Frost Vision Camera',
}

const displayName = computed(() => deviceName[props.deviceType]);
const latest = computed(() => setupStore.latest);
const timeoutHandle = ref(null);
const hideReadings = computed(() => props.deviceType.match(/fvc/i) !== -1);

async function getLastPhoto(id) {
    try {
        await setupStore.getLastPhoto(id);
        errorMessage.value = null;
        clearTimeout(timeoutHandle.value);
        timeoutHandle.value = setTimeout(() => {
            getLastPhoto(id);
        }, 15000);
    } catch (error) {
        if (error.status === 404) {
            errorMessage.value = 'Waiting for the photo to finish processing.';
            timeoutHandle.value = setTimeout(() => {
                getLastPhoto(id);
            }, 15000);
        } else {
            errorMessage.value = error;
        }
    }
}

const retakePhoto = async () => {
    clearTimeout(timeoutHandle.value);
    const id = setupStore.setupDevice.ID;
    await setupStore.requestPhoto(id);
    getLastPhoto(id);
}

onMounted(async () => {
    let id = setupStore.setupDevice.ID;
    try {
        if (!id) {
            await setupStore.getDeviceByVendorId(props.id);
        }
        id = setupStore.setupDevice.ID;
        await setupStore.runDiagnostics(id);
        await setupStore.requestPhoto(id);
    } catch (error) {
        errorMessage.value = error;
    }
    getLastPhoto(id);
})

const handleBack = () => {
    setupStore.goToPreviousStep();
}

const next = async () => {
    try {
        await setupStore.finalizeSetup(setupStore.setupDevice.ID);
        setupStore.goToNextStep();
    } catch (error) {
        errorMessage.value = error;
    }
}

const disabled = computed(() => {
    return !setupStore.setupDevice || !setupStore.diagnostics || setupStore.diagnostics.diagnosticStatus !== 'ok';
})
</script>