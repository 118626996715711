import ConfirmSensorStatus from "./confirmSensorStatus.vue";
import SensorDetailsForm from "./SensorDetailsForm.vue";
import ConfirmCameraPlacement from "./confirmCameraPlacement.vue";
import PlacementMeasurementsForm from "./placementMeasurementsForm.vue";

const deviceSetupFlows = {
  RWIS_V1: {
    title: "Add New Mini-RWIS",
    routeAfterCompletion: "/?sensorId=${sensorId}",
    routeAfterCancel: "/settings/locations",
    steps: [
      {
        subtitle: "Assemble solar panel and battery unit",
        content: [
          {
            title: "Assemble the Solar Panels",
            text: [
              "Place two solar panels face down on a smooth, flat surface taking care not to scratch the panels.",
              "Connect the panels by sliding the 2 steel bars smooth side up into the slots on the top and bottom of the panels.",
              "Secure with the 4 square washer screws.",
            ],
            imageUrl: require("../../images/instructions/assembly_1.svg"),
          },
          {
            title: "Connect the Solar Panel Cables",
            text: [
              "Connect the orange tagged solar panel cables.",
              "Rotate both sides of cable locking connector until hand tight.",
            ],
            imageUrl: require("../../images/instructions/assembly_2.svg"),
          },
          {
            title: "Attach the mounting bracket",
            text: ["Attach mounting bracket using 4 square washer screws."],
            imageUrl: require("../../images/instructions/assembly_3.svg"),
          },
          {
            title: "Attach the Mounting Plate",
            text: [
              "Place two solar panels face down on a smooth, flat surface taking care not to scratch the panels.",
              "Connect the panels by sliding the 2 steel bars smooth side up into the slots on the top and bottom of the panels.",
              "Secure with the 4 square washer screws.",
            ],
            imageUrl: require("../../images/instructions/rwis_v1_assembly_4.png"),
          },
        ],
      },
      {
        subtitle: "When at Site of Installation - Power on Sensor",
        content: [
          {
            title: "Connect Battery to Sensor",
            text: [
              "Connect the blue tagged cables from the battery to the blue tagged cable on the RWIS sensor box.",
              "Rotate both sides of cable locking connector until hand tight.",
            ],
            imageUrl: require("../../images/instructions/power_sensor_1.png"),
          },
          {
            title: "Power on the RWIS",
            text: [
              "Power on the device by switching the red power switch located on the bottom of the arm.",
              "The green light next to the switch should blink. A green light should also flash within the Mini-RWIS sensor unit",
            ],
            imageUrl: require("../../images/instructions/power_sensor_2.svg"),
          },
        ],
      },
      {
        subtitle: "Assign Sensor Details and Location",
        component: SensorDetailsForm,
        hideFooter: true,
      },
      {
        subtitle: "Confirm Sensor Status",
        component: ConfirmSensorStatus,
        hideFooter: true,
      },
      {
        subtitle: "Mount Mini-RWIS",
        content: [
          {
            title: "Position the unit",
            text: [
              "Position the unit on a pole roughly 12-20 feet (3.5-6 meters) off the ground.",
              "Mount the unit by fastening the pole bands around the pole and through the holes on the battery arm.",
              "Tighten pole bands until secure.",
            ],
            imageUrl: require("../../images/instructions/pole_mount.svg"),
          },
        ],
      },
      {
        subtitle: "Install Solar Panel",
        content: [
          {
            title: "Attach the Solar Panel to the Arm",
            text: [
              "Slide the solar panel mounting bracket into the corresponding portion of the battery arm.",
              "When sliding on, make sure the Solar Panel is facing South.",
              "Secure with three self tapping screws.",
            ],
            imageUrl: require("../../images/instructions/solar_panel_1.svg"),
          },
          {
            title: "Connect Solar Panel to the Battery",
            text: [
              "Connect the green tagged charging cables from the solar panel to the battery unit.",
              "Rotate both sides of cable locking connector until hand tight.",
            ],
            imageUrl: require("../../images/instructions/solar_panel_2.svg"),
          },
        ],
      },
      {
        subtitle: "Aiming your Mini-RWIS",
        hideFooter: true,
        content: [
          {
            title: "Aim Laser Pointer",
            text: [
              "Set the laser pointer on top of the sensor housing to assist with aiming the infrared temperature sensor.",
              "Note: Your laser should be removed when aiming is complete, and re-used if you have multiple units.",
            ],
            imageUrl: require("../../images/instructions/aim_sensor_1.png"),
          },
          {
            title: "Aim the Infrared Sensor",
            text: [
              "Tilt the sensor bracket until the laser dot is visible where you'd like to take your pavements temperature readings.",
              "For the most accurate temperature readings, the sensor should not be aimed at paint lines.",
            ],
            imageUrl: require("../../images/instructions/aim_sensor_4.svg"),
          },
          {
            title: "Tighten Infrared Sensor Bracket",
            text: ["Tighten Sensor Bracket bolts."],
            imageUrl: require("../../images/instructions/aim_sensor_3.png"),
          },
          {
            title: "Aim the Camera",
            text: [
              "Loosen the bolt to position the camera as desired.",
              "Tighten the bolt to secure.",
              "The wide field of view will allow you to see a broad area.",
            ],
            imageUrl: require("../../images/instructions/aim_sensor_2.svg"),
          },
          {
            title: "Confirm Positioning",
            text: [
              "Confirm the Infrared sensor is still positioned accurately.",
            ],
            imageUrl: require("../../images/instructions/aim_sensor_4.svg"),
          },
          {
            title: "Enter Placement Measurements of Mini-RWIS",
            component: PlacementMeasurementsForm,
          },
        ],
      },
      {
        subtitle: "Please make any final adjustements to the camera",
        component: ConfirmCameraPlacement,
        hideFooter: true,
      },
    ],
  },
  FVC_12: {
    title: "Add New Frost Vision Camera",
    routeAfterCompletion: "/?sensorId=${sensorId}",
    routeAfterCancel: "/settings/locations",
    hiddenFields: ["LocationType", "SurfaceType"],
    steps: [
      {
        subtitle: "Assemble solar panel and battery unit",
        content: [
          {
            title: "Assemble the Solar Panels",
            text: [
              "Place two solar panels face down on a smooth, flat surface taking care not to scratch the panels.",
              "Connect the panels by sliding the 2 steel bars smooth side up into the slots on the top and bottom of the panels.",
              "Secure with the 4 square washer screws.",
            ],
            imageUrl: require("../../images/instructions/assembly_1.svg"),
          },
          {
            title: "Connect the Solar Panel Cables",
            text: [
              "Connect the orange tagged solar panel cables.",
              "Rotate both sides of cable locking connector until hand tight.",
            ],
            imageUrl: require("../../images/instructions/assembly_2.svg"),
          },
          {
            title: "Attach the mounting bracket",
            text: ["Attach mounting bracket using 4 square washer screws."],
            imageUrl: require("../../images/instructions/assembly_3.svg"),
          },
          {
            title: "Attach the Mounting Plate",
            text: [
              "Place two solar panels face down on a smooth, flat surface taking care not to scratch the panels.",
              "Connect the panels by sliding the 2 steel bars smooth side up into the slots on the top and bottom of the panels.",
              "Secure with the 4 square washer screws.",
            ],
            imageUrl: require("../../images/instructions/fvc_assembly_4.svg"),
          },
        ],
      },
      {
        subtitle: "When at Site of Installation - Power on Sensor",
        content: [
          {
            title: "Connect Battery to Sensor",
            text: [
              "Connect the blue tagged cables from the battery to the blue tagged cable on the RWIS sensor box.",
              "Rotate both sides of cable locking connector until hand tight.",
            ],
            imageUrl: require("../../images/instructions/fvc_connect_old_battery.svg"),
          },
          {
            title: "Power on the Frost Vision Camera",
            text: [
              "Power on the device by switching the red power switch located on the bottom of the arm.",
              "The green light next to the switch should blink. A green light should also flash within the Mini-RWIS sensor unit",
            ],
            imageUrl: require("../../images/instructions/fvc_power_on.svg"),
          },
        ],
      },
      {
        subtitle: "Assign Sensor Details and Location",
        component: SensorDetailsForm,
        hideFooter: true,
      },
      {
        subtitle: "Confirm Sensor Status",
        component: ConfirmSensorStatus,
        hideFooter: true,
      },
      {
        subtitle: "Mount Mini-RWIS",
        content: [
          {
            title: "Position the unit",
            text: [
              "Position the unit on a pole roughly 12-20 feet (3.5-6 meters) off the ground.",
              "Mount the unit by fastening the pole bands around the pole and through the holes on the battery arm.",
              "Tighten pole bands until secure.",
            ],
            imageUrl: require("../../images/instructions/pole_mount.svg"),
          },
        ],
      },
      {
        subtitle: "Install Solar Panel",
        content: [
          {
            title: "Attach the Solar Panel to the Arm",
            text: [
              "Slide the solar panel mounting bracket into the corresponding portion of the battery arm.",
              "When sliding on, make sure the Solar Panel is facing South.",
              "Secure with three self tapping screws.",
            ],
            imageUrl: require("../../images/instructions/solar_panel_1.svg"),
          },
          {
            title: "Connect Solar Panel to the Battery",
            text: [
              "Connect the green tagged charging cables from the solar panel to the battery unit.",
              "Rotate both sides of cable locking connector until hand tight.",
            ],
            imageUrl: require("../../images/instructions/solar_panel_2.svg"),
          },
        ],
      },
      {
        subtitle: "Aiming your Mini-RWIS",
        hideFooter: true,
        content: [
          {
            title: "Aim the Camera",
            text: [
              "Loosen the bolt to position the camera as desired.",
              "Tighten the bolt to secure.",
              "The wide field of view will allow you to see a broad area.",
            ],
            imageUrl: require("../../images/instructions/aim_sensor_2.svg"),
          },
          {
            title: "Confirm Positioning",
            text: ["Confirm the camera is still positioned accurately."],
            imageUrl: require("../../images/instructions/aim_sensor_4.svg"),
          },
          {
            title: "Enter Placement Measurements of Mini-RWIS",
            component: PlacementMeasurementsForm,
          },
        ],
      },
      {
        subtitle: "Please make any final adjustements to the camera",
        component: ConfirmCameraPlacement,
        hideFooter: true,
      },
    ],
  },
};

deviceSetupFlows.RWIS_S = {
  title: "Add New Mini-RWIS",
  routeAfterCompletion: "/?sensorId=${sensorId}",
  routeAfterCancel: "/settings/locations",
  videoUrl: "https://youtu.be/-LRIlqGU7GA",
  steps: [
    {
      subtitle: "Assign Sensor Details and Location",
      component: SensorDetailsForm,
      hideFooter: true,
    },
    {
      subtitle: "Assemble unit",
      displayVideo: true,
      content: [
        {
          title: "Connect sensor to camera enclosure",
          imageUrl: require("../../images/instructions/rwis_connect_sensor.svg"),
        },
        {
          title: "Connect device to battery enclosure",
          imageUrl: require("../../images/instructions/rwis_connect_battery.svg"),
        },
        {
          title:
            "Connect charging cable to cable coming out of battery enclosure ",
          imageUrl: require("../../images/instructions/rwis_connect_charger.svg"),
          text: [
            "Suggested pre-installation charge time for each battery is 6 hours.",
          ],
        },
      ],
    },
    {
      subtitle: "Mount Mini-RWIS",
      hideFooter: true,
      content: [
        {
          title: "Position the unit",
          text: [
            "Position the unit on a pole roughly 12-20 feet (3.5-6 meters) off the ground.",
            "Mount the unit by fastening the pole bands around the pole and through the holes on the battery arm.",
            "Tighten pole bands until secure.",
          ],
          imageUrl: require("../../images/instructions/pole_mount.svg"),
        },
        {
          title: "Record Placement",
          component: PlacementMeasurementsForm,
        },
      ],
    },
    {
      subtitle: "Position the Solar Panel",
      content: [
        {
          title: "Position solar panel facing south",
          imageUrl: require("../../images/instructions/solar_panel_1.svg"),
        },
        {
          title:
            "Plug solar panel cable into cable coming out of battery enclosure.",
          imageUrl: require("../../images/instructions/solar_panel_2.svg"),
        },
      ],
    },
    {
      subtitle: "Diagnostic Check",
      component: ConfirmSensorStatus,
      hideFooter: true,
    },
    {
      subtitle: "Aiming your Mini-RWIS",
      content: [
        {
          title: "Aim Laser Pointer",
          text: [
            "Set the laser pointer on top of the sensor housing to assist with aiming the infrared temperature sensor.",
            "Note: Your laser should be removed when aiming is complete, and re-used if you have multiple units.",
          ],
          imageUrl: require("../../images/instructions/rwis_assembly_view.png"),
        },
        {
          title: "Aim the Infrared Sensor",
          text: [
            "Tilt the sensor bracket until the laser dot is in the center of where you want to capture.",
          ],
          imageUrl: require("../../images/instructions/aim_sensor_4.svg"),
        },
        {
          title: "Tighten Infrared Sensor Bracket",
          text: ["Tighten Sensor Bracket bolts."],
          imageUrl: require("../../images/instructions/rwis_laser_assembly_side.png"),
        },
        {
          title: "Aim the Camera",
          text: [
            "Loosen the bolt to position the camera as desired.",
            "Tighten the bolt to secure.",
            "The wide field of view will allow you to see a broad area.",
          ],
          imageUrl: require("../../images/instructions/aim_sensor_2.svg"),
        },
        {
          title: "Confirm Positioning",
          text: ["Confirm the Infrared sensor is still positioned accurately."],
          imageUrl: require("../../images/instructions/aim_sensor_4.svg"),
        },
      ],
    },
    {
      subtitle: "Please make any final adjustements to the camera",
      component: ConfirmCameraPlacement,
      hideFooter: true,
    },
  ],
};

deviceSetupFlows.RWIS_12 = { ...deviceSetupFlows.RWIS_S };
deviceSetupFlows.FVC_S = {
  title: "Add New Frost Vision Camera",
  routeAfterCompletion: "/?sensorId=${sensorId}",
  routeAfterCancel: "/settings/locations",
  steps: [
    {
      subtitle: "Assign Camera Details and Location",
      component: SensorDetailsForm,
      hideFooter: true,
    },
    {
      subtitle: "Assemble unit",
      content: [
        {
          title: "Connect device to battery enclosure",
          imageUrl: require("../../images/instructions/fvc_connect_battery.svg"),
        },
        {
          title:
            "Connect charging cable to cable coming out of battery enclosure ",
          imageUrl: require("../../images/instructions/fvc_connect_charger.svg"),
          text: [
            "Suggested pre-installation charge time for each battery is 6 hours.",
          ],
        },
      ],
    },
    {
      subtitle: "Mount Frost Vision Camera",
      hideFooter: true,
      content: [
        {
          title: "Position the unit",
          text: [
            "Position the unit on a pole roughly 12-20 feet (3.5-6 meters) off the ground.",
            "Mount the unit by fastening the pole bands around the pole and through the holes on the battery arm.",
            "Tighten pole bands until secure.",
          ],
          imageUrl: require("../../images/instructions/pole_mount.svg"),
        },
        {
          title: "Record Placement",
          component: PlacementMeasurementsForm,
        },
      ],
    },
    {
      subtitle: "Position the Solar Panel",
      content: [
        {
          title: "Position solar panel facing south",
          imageUrl: require("../../images/instructions/solar_panel_1.svg"),
        },
        {
          title:
            "Plug solar panel cable into cable coming out of battery enclosure.",
          imageUrl: require("../../images/instructions/solar_panel_2.svg"),
        },
      ],
    },
    {
      subtitle: "Diagnostic Check",
      component: ConfirmSensorStatus,
      hideFooter: true,
    },
    {
      subtitle: "Aiming your Frost Vision Camera",
      content: [
        {
          title: "Aim the Camera",
          text: [
            "Loosen the bolt to position the camera as desired.",
            "Tighten the bolt to secure.",
            "The wide field of view will allow you to see a broad area.",
          ],
          imageUrl: require("../../images/instructions/aim_sensor_2.svg"),
        },
        {
          title: "Confirm Positioning",
          text: ["Confirm everything is still positioned correctly."],
          imageUrl: require("../../images/instructions/aim_sensor_4.svg"),
        },
      ],
    },
    {
      subtitle: "Please make any final adjustements to the camera",
      component: ConfirmCameraPlacement,
      hideFooter: true,
    },
  ],
};
deviceSetupFlows.FVC_SI = { ...deviceSetupFlows.FVC_S };
deviceSetupFlows.FVC_12I = { ...deviceSetupFlows.FVC_12 };

export default deviceSetupFlows;
