<template>
    <div class="flex-row space-between flex-wrap gap-3">
        <div class="flex-row">
            <span class="text-mono mr-3">Signal Strength: {{ isNumber(signalStrength) ? signalStrength + '%' :
                '-' }}</span>
            <i v-if="signalStrength === '-'" class="fa fa-signal text-light-gray"></i>
            <i v-else-if="signalStrength > 60" class="fa fa-signal text-green"></i>
            <i v-else-if="signalStrength < 60 && signalStrength > minSignalStrength"
                class="fa fa-signal text-yellow"></i>
            <i v-else class="fa fa-signal text-red"></i>
        </div>
        <div class="flex-row">
            <span class="text-mono mr-3">Diagnostic status: {{ diagnosticStatus.toUpperCase() }}</span>
            <i v-if="diagnosticStatus === 'ok'" class="fa fa-check-circle text-green"></i>
            <i v-else class="fa fa-times-circle text-red"></i>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { isNumber } from 'lodash';
import { useSetupStore } from '../../js/store';

const setupStore = useSetupStore();

const diagnosticStatus = computed(() => {
    if (setupStore.diagnostics.diagnosticStatus) {
        return setupStore.diagnostics.diagnosticStatus;
    }
    return '-';
});

const signalStrength = computed(() => {
    if (setupStore.diagnostics.signalStrength && isNumber(setupStore.diagnostics.signalStrength)) {
        return setupStore.diagnostics.signalStrength;
    }
    return 0;
});

const minSignalStrength = computed(() => {
    if (setupStore.diagnostics.minSignalStrength && isNumber(setupStore.diagnostics.minSignalStrength)) {
        return setupStore.diagnostics.minSignalStrength;
    }
    return 10;
});
</script>