<template>
    <div>
        <qr-code-scanner :handleSuccessfulDecode="handleSuccessfulDecode" :validationPending="validationPending"
            :validationSuccessful="validationSuccessful" :handleReset="handleReset" :paused="paused" />
        <div class="flex-row mt-3">
            <i class="fa fa-qrcode mr-3"></i>
            <div class="flex-column">
                <h3 class="m-0">Scan Setup Code</h3>
                <p class="m-0">Look for a QR code on the sensor or in the instructions and position it in the camera
                    frame above</p>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-secondary mr-2" @click="handleCloseModal">
                <i class="fa fa-close"></i>
                Cancel</button>
            <button class="btn btn-primary" @click="handleNext" :disabled="disabled">
                <i class="fa fa-arrow-right"></i>
                Continue</button>
        </div>
    </div>
</template>
<script setup>
import QrCodeScanner from './qrCodeScanner.vue';
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router/composables';
import { useSetupStore } from '../../js/store';

const emit = defineEmits([
    'update-title',
    'update-subtitle',
])
onMounted(() => {
    emit('update-title', 'Scan Device')
    emit('update-subtitle', 'Scan the QR code on the device or enter the serial number')
})

const router = useRouter();
const setupStore = useSetupStore();

const data = ref({})
const validationPending = ref(false);
const validationSuccessful = ref(null);
const paused = computed(() => validationPending.value || validationSuccessful.value !== null);

const handleReset = () => {
    data.value = {};
    validationPending.value = false;
    validationSuccessful.value = null;
};

const handleSuccessfulDecode = (decoded) => {
    data.value = decoded;
    setupStore.getDeviceByVendorId(data.value.id);
    if (setupStore.setupDevice) {
        validationSuccessful.value = true;
    } else {
        validationSuccessful.value = false;
    }
    validationPending.value = false;
};

const handleCloseModal = () => {
    // Go back to the previous page
    router.push({ name: 'sensors' });
};

const handleNext = () => {
    router.push({
        name: 'setup-steps',
        params: { id: data.value.id, deviceType: data.value.producttype, step: 1 },
    });
};

const disabled = computed(() => {
    return !data.value.id || !data.value.producttype;
});


</script>