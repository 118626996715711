import { defineStore } from "pinia";
import api from "../api";
import deviceSetupFlows from "../../components/setup/deviceSetupFlows";
import { useGroupsStore } from "./groups";
import { transformMostRecentData } from "../transformers";

const API = new api();

export const useSetupStore = defineStore("setup", {
  state: () => ({
    setupDevice: {},
    errorMessage: null,
    diagnostics: {
      signalStrength: null,
      diagnosticStatus: null,
      minSignalStrength: 10,
      errorMessage: null,
    },
    vendorId: null,
    deviceType: null,
    steps: [],
    stepIndex: 0,
    currentStep: null,
    completeRoute: null,
    cancelRoute: null,
    latest: {},
    hiddenFields: [],
    title: "Add Frost device",
  }),
  getters: {
    isFirstStep() {
      return this.stepIndex === 0;
    },
    isLastStep() {
      return this.stepIndex === this.steps.length - 1;
    },
  },
  actions: {
    initStore({ vendorId, deviceType, step }) {
      this.vendorId = vendorId;
      this.deviceType = deviceType;
      this.steps = deviceSetupFlows[deviceType].steps || [];
      this.hiddenFields = deviceSetupFlows[deviceType].hiddenFields || [];
      this.stepIndex = step - 1;
      this.completeRoute = deviceSetupFlows[deviceType].routeAfterCompletion;
      this.cancelRoute = deviceSetupFlows[deviceType].routeAfterCancel;
      this.currentStep = this.steps[step - 1];
      this.title = deviceSetupFlows[deviceType].title || "Add Frost device";
      this.videoUrl = deviceSetupFlows[deviceType].videoUrl || null;
    },
    setStep(step) {
      const intStep = parseInt(step);
      if (intStep < 1 || intStep > this.steps.length) {
        console.error("Invalid step index", intStep);
        return;
      }
      if (this.stepIndex === intStep - 1) {
        console.error("Already on this step", intStep);
        return;
      }
      this.router.push({
        name: "setup-steps",
        params: {
          id: this.vendorId,
          deviceType: this.deviceType,
          step: intStep,
        },
      });
    },
    goToNextStep() {
      if (this.isLastStep) {
        let nextRoute = this.completeRoute || "/";
        nextRoute = nextRoute.replace("${sensorId}", this.setupDevice.ID);
        this.router.push(nextRoute);
        return;
      }
      const currentStepInt = this.stepIndex + 1;
      this.setStep(currentStepInt + 1);
    },
    goToPreviousStep() {
      if (this.isFirstStep) {
        this.router.push({
          name: "scan",
        });
        return;
      }
      const currentStepInt = this.stepIndex + 1;
      this.setStep(currentStepInt - 1);
    },
    closeModal() {
      this.$reset();
      this.router.push({
        name: "sensors",
      });
    },
    $reset() {
      this.setupDevice = {};
      this.errorMessage = null;
      this.diagnostics = {
        signalStrength: null,
        diagnosticStatus: null,
        minSignalStrength: 10,
        errorMessage: null,
      };
      this.vendorId = null;
      this.deviceType = null;
      this.steps = [];
      this.stepIndex = 0;
      this.currentStep = null;
      this.completeRoute = null;
      this.cancelRoute = null;
      this.latest = {};
    },
    async getDeviceByVendorId(vendorId) {
      try {
        const response = await API.getDeviceByVendorId(vendorId);
        const deviceId = response.ID;
        const fullDevice = await API.getDevice(deviceId);
        this.setupDevice = fullDevice;
        return new Promise((resolve) => {
          resolve(response);
        });
      } catch (error) {
        this.errorMessage = error;
      }
    },
    async getDevice(deviceId) {
      try {
        const response = await API.getDevice(deviceId);
        this.setupDevice = response;
        return new Promise((resolve) => {
          resolve(response);
        });
      } catch (error) {
        this.errorMessage = error;
      }
    },
    async runDiagnostics(deviceId) {
      if (!deviceId) {
        this.diagnostics.errorMessage = "No device ID provided";
        return;
      }
      try {
        const response = await API.runDiagnostics(deviceId);
        if (response.message) {
          this.diagnostics = { errorMessage: response.message };
          return new Promise((resolve) => {
            resolve(response);
          });
        } else {
          this.diagnostics = response;
          return new Promise((resolve) => {
            resolve(response);
          });
        }
      } catch (error) {
        this.diagnostics.errorMessage = error;
      }
    },
    async saveInstallParams(data) {
      try {
        const response = await API.saveInstallParams(this.setupDevice.ID, data);
        return new Promise((resolve) => {
          resolve(response);
        });
      } catch (error) {
        console.error(error);
      }
    },
    async getDeviceInfo(deviceId) {
      try {
        const response = await API.getDeviceInformation(deviceId);
        // TODO: Transform the response data
        this.deviceInfo = response;
      } catch (error) {
        console.error(error);
      }
    },
    async updateDevice(data) {
      try {
        const response = await API.updateDevice(this.setupDevice.ID, data);
        this.setupDevice = response;
        return new Promise((resolve) => {
          resolve(response);
        });
      } catch (error) {
        console.error(error);
      }
    },
    replaceDevice(existingId, newDeviceVendorDeviceId) {
      return new Promise((resolve, reject) => {
        API.patchReplaceDevice(existingId, newDeviceVendorDeviceId)
          .then((response) => {
            this.setupDevice = response.DeviceInfo;
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    requestPhoto(deviceId) {
      return new Promise((resolve, reject) => {
        API.requestPhoto(deviceId).then((response) => {
          if (response && response.allowPhoto === 0) {
            reject();
          }
          if (response && response.allowPhoto === 1) {
            return resolve();
          }
        });
      });
    },
    async getLastPhoto(deviceId) {
      const groupsStore = useGroupsStore();
      try {
        const response = await API.getMostRecent([deviceId]);
        const {
          TemperatureUnits: tempUnit,
          TimeZone: timeZone,
          isMetric,
        } = groupsStore.selectedGroup;
        for (let deviceId in response) {
          const DataTransmission = response[deviceId];
          this.latest = await transformMostRecentData(DataTransmission, {
            timeZone,
            tempUnit,
            isMetric,
          });
        }
        return new Promise((resolve) => {
          resolve(this.latest);
        });
      } catch (error) {
        console.error(error);
      }
    },
    async finalizeSetup(deviceId) {
      try {
        const response = await API.sendNotification(deviceId);
        return response;
      } catch (error) {
        return error;
      }
    },
  },
});
