<template>
    <ejs-dialog :id="modalName" :target="sfTarget" :ref="modalName" :header="lblTitle" isModal="true"
        showCloseIcon="true" width="500px" style="height: 100% Im !important;">
        <div class="dm-position-relative">
            <ejs-accordion ref="accordion" expandMode="Single" :expanding="beforeExpand" :select="stepIndex">
                <div>
                    <div>
                        <div class="dm-flex-row dm-align-items-center">
                            <div class="app-number-circle dm-margin-right-sm">
                                <div v-if="stepIndex === 0" class="dm-position-relative">
                                    <div class="app-number-circle-circle"></div>
                                    <div class="app-number-circle-number">
                                        <span>1</span>
                                    </div>
                                </div>
                                <div v-if="stepIndex > 0" class="dm-position-relative">
                                    <div class="app-number-circle-circle"></div>
                                    <div class="app-number-circle-number">
                                        <i class="fa fa-check"></i>
                                    </div>
                                </div>
                            </div>
                            Location
                        </div>
                    </div>
                    <div>
                        <div class="dm-flex-column dm-padding-md">
                            <div class="dm-flex-row dm-margin-bottom-md">
                                <div class="dm-flex-1 dm-margin-right-md">
                                    <ejs-textbox v-model="latitude" floatLabelType="Auto" placeholder="Latitude"
                                        :width="'100%'">
                                    </ejs-textbox>
                                </div>

                                <div class="dm-flex-1">
                                    <ejs-textbox v-model="longitude" floatLabelType="Auto" placeholder="Longitude"
                                        :width="'100%'">
                                    </ejs-textbox>
                                </div>
                            </div>

                            <div class="app-location-map-container">
                                <img src="../../images/google-maps-marker.png" class="app-google-maps-marker-image"
                                    alt="Google Maps Marker" />

                                <div id="app-locations-map"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div class="dm-flex-row dm-align-items-center">
                            <div class="app-number-circle dm-margin-right-sm">
                                <div v-if="stepIndex <= 1" class="dm-position-relative">
                                    <div class="app-number-circle-circle"></div>
                                    <div class="app-number-circle-number">
                                        <span>2</span>
                                    </div>
                                </div>
                                <div v-if="stepIndex > 1" class="dm-position-relative">
                                    <div class="app-number-circle-circle"></div>
                                    <div class="app-number-circle-number">
                                        <i class="fa fa-check"></i>
                                    </div>
                                </div>
                            </div>
                            Details
                        </div>
                    </div>
                    <div>
                        <div class="dm-flex-column dm-padding-md">
                            <div class="dm-flex-1 dm-margin-bottom-md">
                                <ejs-textbox v-model="name" floatLabelType="Auto" placeholder="Location Name">
                                </ejs-textbox>
                            </div>

                            <div class="dm-flex-1 dm-margin-bottom-md">
                                <ejs-textbox v-model="zone" floatLabelType="Auto" placeholder="Zone">
                                </ejs-textbox>
                            </div>
                            <div v-if="displayLocationType" class="dm-flex-row dm-margin-bottom-md">
                                <div class="dm-flex-1 dm-margin-right-md">
                                    <ejs-dropdownlist v-model="locationType" :dataSource="locationTypeOptions"
                                        :fields="{ text: 'text', value: 'value' }" :allowFiltering="true"
                                        floatLabelType="Auto" placeholder="Location Type" :width="'100%'"
                                        :ref="'locationTypeRef'" :open="() => addSearchIcon($refs.locationTypeRef)" />
                                </div>

                                <div v-if="displaySurfaceType" class="dm-flex-1">
                                    <ejs-dropdownlist v-model="surfaceType" :dataSource="surfaceTypeOptions"
                                        :fields="{ text: 'text', value: 'value' }" :allowFiltering="true"
                                        floatLabelType="Auto" placeholder="Surface Type" :width="'100%'"
                                        :ref="'surfaceTypeRef'" :open="() => addSearchIcon($refs.surfaceTypeRef)" />
                                </div>
                            </div>

                            <div class="dm-flex-row dm-margin-bottom-md">
                                <div class="dm-flex-1 dm-margin-right-md">
                                    <ejs-textbox v-model="height" floatLabelType="Auto"
                                        placeholder="Height from ground (ft)"></ejs-textbox>
                                </div>

                                <div class="dm-flex-1">
                                    <ejs-textbox v-model="distance" floatLabelType="Auto"
                                        placeholder="Distance from Road (ft)"></ejs-textbox>
                                </div>
                            </div>

                            <div class="dm-flex-1 dm-margin-bottom-md">
                                <ejs-textbox v-model="altitude" floatLabelType="Auto"
                                    placeholder="Location Altitude (ft)"></ejs-textbox>
                            </div>

                            <div class="dm-flex-1 dm-margin-bottom-md">
                                <ejs-textbox v-model="notes" :multiline="true" floatLabelType="Auto"
                                    placeholder="Notes">
                                </ejs-textbox>
                            </div>
                        </div>
                    </div>
                </div>
            </ejs-accordion>
        </div>

        <div class="dm-flex-row dm-justify-content-space-between dm-margin-md">
            <div>
                <ejs-checkbox v-model="isActive" label="Active"> </ejs-checkbox>
            </div>

            <div>
                <ejs-button class="dm-margin-right-md" @click.native="btnBackOnClick" :disabled="stepIndex === 0">
                    Back
                </ejs-button>

                <ejs-button v-if="stepIndex !== 1" @click.native="btnNextOnClick" is-primary="true"
                    :disabled="!validateCurrentStep">
                    Next
                </ejs-button>

                <ejs-button v-if="stepIndex === 1" @click.native="btnNextOnClick" is-primary="true"
                    :disabled="!validateCurrentStep">
                    Save
                </ejs-button>
            </div>
        </div>
    </ejs-dialog>

</template>

<script>
import { mapStores } from 'pinia';
import { useBaseStore } from '../../js/store';
import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin';

export default {
    name: 'locations-edit-modal',
    mixins: [dmModalMixin],
    props: [
        "modalData",
    ],
    data: function () {
        return {
            modalName: 'locationsEditModal' + DM.getRandomGuid(),
            lblTitle: 'Add Location',
            reading: '',
            readingOptions: [
                {
                    text: 'Surface Temp',
                    value: 'Surface Temp',
                },
                {
                    text: 'Air Temp',
                    value: 'Air Temp',
                },
                {
                    text: 'Humidity',
                    value: 'Humidity',
                },
                {
                    text: 'Dew Point',
                    value: 'Dew Point',
                },
                {
                    text: 'Freeze Alert',
                    value: 'Freeze Alert',
                },
                {
                    text: 'Location Offline',
                    value: 'Location Offline',
                }
            ],
            temperature: '',
            stepIndex: 0,
            addASecondCondition: false,
            template: function () {
                return {
                    template: Vue.component('bindDropdown', {
                        template: `<ejs-dropdownlist 
                                id='dropdownlist' 
                                :dataSource='dropData' 
                                :ref="'dropdownRef'"
                                :open="() => addSearchIcon($refs.dropdownRef)" 
                            />`,
                        data() {
                            return {
                                dropData: ['Order Placed', 'Processing', 'Delivered']
                            }
                        },
                        method: {
                            addSearchIcon(ref) {
                                if (ref) {
                                    DM.addSearchIconDropDown(ref);
                                }
                            },
                        }
                    })
                }
            },
            map: null,
            showMarker: false,
            nextWasClicked: true,

            id: '',
            latitude: '',
            longitude: '',
            name: '',
            group: null,
            groupOptions: [],
            zone: '',
            locationType: null,
            locationTypeOptions: [],
            surfaceType: null,
            surfaceTypeOptions: [],
            height: '',
            distance: '',
            altitude: '',
            notes: '',
            isActive: true,
        }
    },
    computed: {
        displaySurfaceType() {
            return this.modalData.location.DeviceType !== 'Snow Depth Sensor';
        },
        displayLocationType() {
            return this.modalData.location.DeviceType !== 'Snow Depth Sensor';
        },
    },
    methods: {
        addSearchIcon(ref) {
            if (ref) {
                DM.addSearchIconDropDown(ref);
            }
        },
        saveLocation() {
            let isEdit = false;

            if (this.modalData.mode === 'edit') {
                isEdit = true;
            }

            const data = {
                Name: this.name,
                IsActive: this.isActive,
                Latitude: this.latitude,
                Longitude: this.longitude,
                Notes: this.notes,
                Zone: this.zone,
                Height: parseFloat(this.height),
                Distance: parseFloat(this.distance),
                Altitude: parseFloat(this.altitude),
            }
            if (this.displaySurfaceType) {
                data.SurfaceTypeID = this.surfaceType || '';
            }
            if (this.displayLocationType) {
                data.LocationTypeID = this.locationType || '';
            }

            return DM.http({
                method: !isEdit ? "POST" : "PUT",
                url: !isEdit ? '/locations' : `/locations/${this.modalData.location.ID}`,
                data
            });
        },
        btnNextOnClick() {
            if (this.stepIndex < 1) {
                this.stepIndex++;
                this.setExpandedStep();
            } else {
                this.saveLocation()
                    .then(response => {
                        this.modalData.callback();
                        this.modalRef.hide();
                    });
            }
        },
        btnBackOnClick() {
            this.stepIndex--;
            this.setExpandedStep();
        },
        setExpandedStep() {
            for (let i = 0; i < (4 - this.stepIndex); i++) {
                this.$refs.accordion.ej2Instances.vueInstance.enableItem((4 - i), false);
            }
            this.$refs.accordion.ej2Instances.vueInstance.enableItem(this.stepIndex, true);
            this.$refs.accordion.ej2Instances.vueInstance.expandItem(true, this.stepIndex);
        },
        btnCancelOnClick() {
            this.modalRef.hide();
        },
        beforeExpand: function ($event) {
            let obj = this.$refs.accordion.ej2Instances;
            let ele = obj.element.querySelectorAll('.e-selected')[0];
        },
        openScanQrCodeOnClick(serialNumberName) {
            eventBus.$emit('showAppModal', this, 'qrCodeScanModal', {
                callback: (detectedSerialNumber) => {
                    this[serialNumberName] = detectedSerialNumber;
                }
            });
        },
        getLocationTypeOptions() {
            DM.http({
                method: 'GET',
                url: '/devices/locationTypes',
            }).then(response => {
                this.locationTypeOptions = response.map(g => {
                    return {
                        text: g.Name,
                        value: g.ID
                    }
                });
            });
        },
        getSurfaceTypeOptions() {
            DM.http({
                method: 'GET',
                url: '/devices/surfaceTypes',
            }).then(response => {
                this.surfaceTypeOptions = response.map(g => {
                    return {
                        text: g.Name,
                        value: g.ID
                    }
                });
            });
        },
        allowLocationServices() {
            DM.addRequest();
            return new Promise(app.getUserPosition)
                .then(position => {
                    DM.removeRequest();
                    return {
                        lat: position.latitude,
                        lng: position.longitude
                    };
                })
                .catch(error => {
                    DM.removeRequest();
                    DM.alertShow('We could not find your location. You may need to allow location services for your browser or application.');
                    return Promise.reject();
                });
        },
        getCenterLocation() {
            if (this.modalData.mode === 'edit') {
                return Promise.resolve({ lat: this.latitude, lng: this.longitude });
            } else {
                return this.allowLocationServices()
                    .then((latLng) => {
                        return latLng;
                    })
                    .catch(error => {
                        return { lat: Number(process.env.VUE_APP_LAT), lng: Number(process.env.VUE_APP_LNG) };
                    });
            }
        }
    },
    computed: {
        ...mapStores(useBaseStore),
        validateCurrentStep() {
            if (this.stepIndex === 0) {
                return this.latitude && this.longitude;
            } else if (this.stepIndex === 1) {
                return this.name;
            }
        },
    },
    watch: {
        latitude(newVal) {
            if (google) {
                let latLng = new google.maps.LatLng(newVal, this.longitude);
                if (this.map) {
                    this.map.setCenter(latLng);
                }
            }
        },
        longitude(newVal) {
            if (google) {
                let latLng = new google.maps.LatLng(this.latitude, newVal);
                if (this.map) {
                    this.map.setCenter(latLng);
                }
            }
        }
    },
    created() {
        this.getLocationTypeOptions();
        this.getSurfaceTypeOptions();

        let location = this.modalData.location;

        this.mode = this.modalData.mode;

        if (this.modalData.mode === 'edit') {
            this.lblTitle = 'Edit Location: ' + location.Name;
            this.name = location.Name;
            this.group = location.Group.ID;
            this.latitude = location.Latitude;
            this.longitude = location.Longitude;
            this.locationType = location.LocationTypeID;
            this.zone = location.Zone;
            this.surfaceType = location.SurfaceTypeID;
            this.height = location.Height;
            this.distance = location.Distance;
            this.altitude = location.Altitude;
            this.isActive = location.IsActive;
            this.notes = location.Notes;

        }
    },
    mounted() {
        this.setExpandedStep();

        let initMap = (center) => {
            this.map = new google.maps.Map(document.getElementById("app-locations-map"), {
                center: center,
                zoom: 15,
                mapTypeId: 'roadmap',
                panControl: false,
                streetViewControl: false,
                fullscreenControl: false,
                clickableIcons: false,
                // scaleControl: true,
                // disableDefaultUI: true
            });

            this.showMarker = true;

            google.maps.event.addListener(this.map, 'dragend', () => {
                this.latitude = this.map.getCenter().lat();
                this.longitude = this.map.getCenter().lng();
            });

            google.maps.event.addListener(this.map, 'zoom_changed', () => {
                this.latitude = this.map.getCenter().lat();
                this.longitude = this.map.getCenter().lng();
            });

            this.latitude = this.map.getCenter().lat();
            this.longitude = this.map.getCenter().lng();
        };

        if (!window.google) {
            window.mapScript = document.createElement('script');
            window.mapScript.src = 'https://maps.google.com/maps/api/js?v=3.58&key=' + process.env.VUE_APP_GOOGLE_MAP_API_KEY + '&amp;libraries=places,geometry,drawing';
            document.body.appendChild(window.mapScript);
            window.mapScript.addEventListener('load', () => {
                this.getCenterLocation()
                    .then((latLng) => {
                        // console.log('getCenterLocation finished', latLng);
                        setTimeout(() => {
                            initMap(latLng);
                        });
                    });
            });
        } else {
            this.getCenterLocation()
                .then((latLng) => {
                    // console.log('getCenterLocation finished', latLng);
                    setTimeout(() => {
                        initMap(latLng);
                    });
                });
        }
    }
}
</script>
