<template>
    <form @submit="handleSubmit">
        <div class="flex-row flex-wrap gap-3">
            <div class="form-group">
                <label for="height">Height ({{ distanceUnit }})</label>
                <input id="height" step=".01" type="number" name="height" placeholder="Height" class="input-grouped"
                    v-model="height" />
            </div>
            <div class="form-group">
                <label for="distanceFromRoad">Distance from Road ({{ distanceUnit }})</label>
                <input id="distanceFromRoad" step=".01" type="number" name="distanceFromRoad"
                    placeholder="Distance from road" class="input-grouped" v-model="distance" />
            </div>
        </div>
        <div>
            <div v-if="errorMessage" class="alert alert-danger mt-3" role="alert">
                <i class="fa fa-exclamation-circle mr-1"></i>{{ errorMessage }}
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-secondary mr-2" @click="handleBack">
                <i class="fa fa-close"></i>
                Back</button>
            <button class="btn btn-primary" :disabled="disabled">
                <i class="fa fa-arrow-right"></i>
                Continue</button>
        </div>
    </form>
</template>

<script setup>

import { defineProps, ref, computed, onMounted } from 'vue';

import { useGroupsStore, useSetupStore } from '../../js/store';

const groupsStore = useGroupsStore();
const setupStore = useSetupStore();

const props = defineProps({
    id: String,
    deviceType: String,
    step: Number,
});
const distanceUnit = groupsStore.metricUnits ? 'm' : 'ft';
const height = ref(null);
const distance = ref(null);
const disabled = computed(() => (!height.value || height.value < 0) || (!distance.value || distance.value < 0));
const errorMessage = ref(null);

async function handleSubmit(e) {
    e.preventDefault();
    try {
        await setupStore.saveInstallParams({
            Height: height.value,
            Distance: distance.value,
        });
        setupStore.goToNextStep();
    } catch (error) {
        errorMessage.value = error.message;
    }
}

function handleBack() {
    setupStore.goToPreviousStep();
}

onMounted(() => {
    if (!setupStore.setupDevice.ID) {
        setupStore.getDeviceByVendorId(props.id);
    }
    // Update height and distance with values from the store
    height.value = setupStore.setupDevice.Height;
    distance.value = setupStore.setupDevice.Distance;
})


</script>