var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_setup.currentStep.displayVideo === true)?_c('div',[_c('span',{staticClass:"bold"},[_vm._v("Setup Video")]),_c('div',{staticClass:"embed-responsive embed-responsive-16by9"},[_c('iframe',{staticClass:"embed-responsive-item",attrs:{"title":"Setup video","src":_setup.embedVideoUrl,"allowfullscreen":""}})]),_c('hr',{staticClass:"mb-3 mt-3"})]):_vm._e(),_vm._l((_setup.currentStep.content),function(content,index){return [_c(_setup.stepContent,{attrs:{"content":content,"step-index":index,"deviceType":_setup.props.deviceType,"id":_setup.props.id,"step":_setup.props.step}})]}),(_setup.currentStep.component)?[_c(_setup.currentStep.component,{tag:"component",attrs:{"deviceType":_setup.props.deviceType,"id":_setup.props.id,"step":_setup.props.step}})]:_vm._e(),(_setup.currentStep.hideFooter !== true)?_c('div',{staticClass:"modal-footer"},[(_setup.videoUrl)?_c('a',{staticClass:"btn btn-outline-secondary mr-auto",attrs:{"href":_setup.videoUrl,"target":"_blank"}},[_c('i',{staticClass:"fa fa-video"}),_vm._v(" Setup Video")]):_vm._e(),_c('button',{staticClass:"btn btn-secondary mr-2",on:{"click":_setup.goBack}},[_c('i',{staticClass:"fa",class:{
                'fa-close': _setup.isFirstStep,
                'fa-arrow-left': !_setup.isFirstStep
            }}),_vm._v(" "+_vm._s(_setup.isFirstStep ? 'Cancel' : 'Back'))]),_c('button',{staticClass:"btn btn-primary",on:{"click":_setup.next}},[_c('i',{staticClass:"fa",class:{
                'fa-arrow-right': !_setup.isLastStep,
                'fa-save': _setup.isLastStep,
            }}),_vm._v(" "+_vm._s(_setup.isLastStep ? 'Finalize' : 'Continue'))])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }