<template>
    <div v-observe-visibility="{
        callback: getData,
        once: true,
        throttle: 500,
    }" class="snow-depth-item flex-column">
        <router-link :to="routerLink" class="device-title" exactActiveClass="text-charcoal">
            <h3 class="text-lg">{{ location.Name }}</h3>
            <battery-capacity :percentage="mostRecent?.MostRecentSnowDepthReading?.batteryPercentage" />
        </router-link>
        <div class="forecast-section">
            <div class="forecast-block">
                <img class="forecast-tile-icon" :src="snowDepthIcon" alt="Snow Depth Icon" />
                <div class="forecast-text-block">
                    <span>Snow Depth</span>
                    <span>{{ snowDepth.snowDepthText !== 'null in' ? snowDepth.snowDepthText : '-' }}</span>
                </div>
            </div>
            <div class="mt-2">
                <span class="uppercase mr-2">Read:</span>
                <span class="bold right">{{ snowDepth.timeSinceRead || 'No readings' }}</span>
            </div>
        </div>
        <div class="preview-image-wrapper filter-bw mt-auto hidden">
            <img class="snowdepth-overlay" :src="snowDepthIcon" alt="presentation" />
            <img :src="image" class="preview-image" alt="background" />
        </div>
        <snow-depth-chart chartKey="grid-" :sensor="location" :data-transmissions="dataTransmissions" height="150px" />
    </div>
</template>

<script>
import { mapStores } from "pinia";
import moment from "moment";

import BatteryCapacity from "./batteryCapacity.vue";
import SnowDepthChart from "./snowDepthChart";

import { useBaseStore, useLocationsStore } from "../js/store";
import { isTabletScreen } from "../js/utils";

import snowDepthIcon from "../images/snow-depth-icon.svg";
import whiteBackground from "../images/white-background.png";

export default {
    name: "grid-ssnowdepth-item",
    props: [
        "location",
        "dataTransmissions",
        "mostRecent",
        "showGridView"
    ],
    components: {
        BatteryCapacity,
        SnowDepthChart,
    },
    data: function () {
        return {
            snowDepthIcon,
            storeLocations: [],
            loading: false,
        };
    },
    methods: {
        openSensor() {
            if (this.location.ID.toString() === this.$route.query.sensorId) {
                return;
            }
            this.$router.push({ query: { ...this.$route.query, sensorId: this.location.ID } })
        },
        openNotifs() {
            return [];
        },
        getData(isVisible) {
            if (isVisible) {
                this.locationsStore.addLocationToQueue(this.location.ID);
            }
        }
    },
    computed: {
        ...mapStores(useBaseStore, useLocationsStore),
        isUserSuperAdmin() {
            return this.baseStore.user?.IsSuperAdmin
        },
        latestTransmission() {
            return this.mostRecent?.MostRecentSnowDepthReading;
        },
        image() {
            return this.locationsStore.snowDepthImage(this.location.ID) || whiteBackground;
        },
        isMobile() {
            return window.innerWidth < 666
        },
        isTablet() {
            return isTabletScreen(true)
        },
        temparatureunit() {
            return this.location?.GroupEmbedded?.TemperatureUnits == "Fahrenheit" ? "°F" : "°C";
        },
        isMetric() {
            return this.location?.GroupEmbedded?.IsMetric;
        },
        snowDepth() {
            const mostRecentSnowDepth = this.mostRecent?.MostRecentSnowDepthReading;
            if (!mostRecentSnowDepth || Object.keys(mostRecentSnowDepth).length === 0) {
                return {};
            }
            const sd = this.isMetric ? mostRecentSnowDepth?.MeasuredHeightMm + ' mm' : mostRecentSnowDepth?.MeasuredHeightInches + ' in';
            const timeSinceRead = moment(mostRecentSnowDepth?.TransmissionDateTimeUTC).fromNow();
            return {
                snowDepthText: sd,
                timeSinceRead,
            }
        },
        routerLink() {
            const query = { ...this.$route.query, sensorId: this.location.ID };
            return {
                query,
            }
        },
    },
    mounted() {

    },
    beforeDestroy() {
    }
};
</script>