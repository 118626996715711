import { defineStore } from "pinia";
import { cloneDeep, includes, reduce } from "lodash";
import moment from "moment-timezone";

import app from "../app";
import api from "../api";
import {
  cleanComputerVisionData,
  transformDataTransmissions,
  transformMostRecentData,
} from "../transformers";
import { computerVisionValueGreaterThanFiftyPercent } from "../utils";
import { useGroupsStore } from "./groups";
import { useBaseStore } from "./base";
import { useForecastsStore } from "./forecasts";

const API = new api();
const BATCH_SIZE_LIMIT_TRANSMISSIONS = 12;

// Locations store
export const useLocationsStore = defineStore("locations", {
  state: () => ({
    searchText: "",
    locations: {}, // {[groupID]: [zones for group]}
    fetchingGroupLocations: false,
    groupLocationsError: null,
    dataTransmissions: {}, // {[deviceID]: [transmissions for device]}
    mostRecentTransmissions: {}, // {[deviceID]: {}}
    fetchingTransmissions: false,
    transmissionsError: null,
    images: {},
    fetchingImages: false,
    imagesError: null,
    startedWorkDeviceIds: [],
    imageTransmissions: {},
    fetchingImageTransmissions: false,
    imageTransmissionsError: null,
    glanceItems: {},
    queuedLocations: [],
  }),
  getters: {
    sensorId() {
      return this.router.currentRoute.query.sensorId;
    },
    groupedLocations() {
      // Returns the locations grouped by deviceType
      // Order of grouping
      const ordered = ["Mini RWIS", "FrostVision Camera", "Snow Depth Sensor"];
      const grouped = this.filteredLocations.reduce((acc, curr) => {
        if (!acc[curr.DeviceType]) {
          acc[curr.DeviceType] = [];
        }
        acc[curr.DeviceType].push(curr);
        return acc;
      }, {});
      return ordered.reduce((acc, curr) => {
        if (grouped[curr]) {
          acc[curr] = grouped[curr];
        }
        return acc;
      }, {});
    },

    filteredLocations() {
      // Returns the locations for the selected group
      const groupsStore = useGroupsStore();
      if (!groupsStore.selectedGroupId) {
        return [];
      }
      const selected = this.locations[groupsStore.selectedGroupId];
      if (!selected) {
        return [];
      }
      return selected;
    },
    devicesByIds() {
      return this.filteredLocations.reduce((acc, curr) => {
        acc[curr.ID] = curr;
        return acc;
      }, {});
    },
    mostRecent(state) {
      return (id) => {
        return state.mostRecentTransmissions[id.toString()];
      };
    },
    snowDepthPairings() {
      return this.filteredLocations.reduce((acc, curr) => {
        if (curr.SnowDepthSensorID) {
          acc[curr.SnowDepthSensorID] = acc[curr.SnowDepthSensorID] || [];
          acc[curr.SnowDepthSensorID].push(curr.ID);
        }
        return acc;
      }, {});
    },
    snowDepthImage(state) {
      return (snowDepthId) => {
        const snowDepthPairings = state.snowDepthPairings[snowDepthId];
        if (!snowDepthPairings) {
          return null;
        }
        const mr = this.mostRecent(snowDepthPairings[0]);
        if (!mr) {
          return null;
        }
        return (
          mr.DataTransmission?.ProcessedCameraImageURL ||
          mr.ComputerVision?.ProcessedCameraImageURL
        );
      };
    },
  },
  actions: {
    getGroupLocations(isRefresh = false, hideAlert = true) {
      const forecastsStore = useForecastsStore();
      this.getLocations(isRefresh, hideAlert).then((locations) => {
        this.getMostRecentTransmissions();
        // Get IDs from the selected group's locations
        const start = moment().subtract(5, "hours").toISOString();
        const end = moment().add(73, "hours").toISOString();
      });
    },
    getImageTransmissions(deviceId, start, end) {
      const groupsStore = useGroupsStore();
      if (!groupsStore.selectedGroupId) {
        return;
      }
      let ids = [deviceId];
      if (ids.length === 0) {
        return;
      }
      const {
        TemperatureUnits: tempUnit,
        TimeZone: timeZone,
        isMetric,
      } = groupsStore.selectedGroup;
      const args = {
        ids,
        includesPhotosOnly: true,
      };
      if (start) {
        args.start = start;
      } else {
        args.start = moment().subtract(1, "week").toISOString();
      }
      if (end) {
        args.end = end;
      } else {
        args.end = moment().toISOString();
      }
      this.fetchingImageTransmissions = true;
      API.getTransmissions(args)
        .then(async (response) => {
          this.fetchingImageTransmissions = false;
          const imageTransmissions = cloneDeep(this.imageTransmissions);
          for (let deviceId in response) {
            imageTransmissions[deviceId] = await transformDataTransmissions(
              response[deviceId],
              {
                timeZone,
                tempUnit,
                isMetric,
              }
            );
          }
          this.imageTransmissions = imageTransmissions;
        })
        .catch((error) => {
          console.error("getImageTransmissions", error);
          if (error.status === 404) {
            this.imagesError = "Failed to fetch data. Please try again later.";
            this.fetchingImages = false;
            return;
          } else {
            this.fetchingImageTransmissions = false;
            this.imageTransmissionsError = error;
          }
        });
    },
    async getLocationsByGroupID(groupID = null, includeInactive = false) {
      if (!groupID) {
        return;
      }
      try {
        const response = await API.getLocations(
          null,
          groupID,
          false,
          true,
          null,
          includeInactive
        );
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    async getLocations(isRefresh = false, hideAlert = true) {
      // Fetches locations for the map view for a selected group
      // Also triggers a getGroupNotification event
      const groupsStore = useGroupsStore();
      if (!groupsStore.selectedGroupId) {
        console.log("No group selected");
        return;
      }
      this.fetchingGroupLocations = true;
      try {
        const response = await API.getLocations(
          this.searchText,
          groupsStore.selectedGroupId,
          isRefresh,
          hideAlert
        );

        const locs = response.map((location) => {
          location.TimeZone = groupsStore.selectedGroup.TimeZone;
          location.GroupEmbedded = {
            ...location.GroupEmbedded,
            TimeZone: groupsStore.selectedGroup.TimeZone,
            TemperatureUnits: groupsStore.selectedGroup.TemperatureUnits,
            IsMetric: groupsStore.selectedGroup.TemperatureUnits === "Celsius",
          };
          return location;
        });
        // Ensure each location ID is unique
        const uniqueLocs = locs.filter(
          (v, i, a) => a.findIndex((t) => t.ID === v.ID) === i
        );
        this.locations = {
          ...this.locations,
          [groupsStore.selectedGroupId]: uniqueLocs,
        };
        this.fetchingGroupLocations = false;
      } catch (error) {
        this.groupLocationsError = error;
        this.fetchingGroupLocations = false;
      }
    },
    addLocationToQueue(deviceId) {
      // Queues up the transmissions for a location
      this.queuedLocations.push(deviceId);
    },
    processQueue() {
      // Processes the queue of locations
      // This is used to fetch transmissions for multiple locations
      // without overloading the API
      if (this.queuedLocations.length === 0) {
        return;
      }
      const deviceIds = this.queuedLocations.splice(0, 20);
      this.getDataTransmissions(deviceIds);
      this.queuedLocations = this.queuedLocations.splice(20);
    },
    // Passing in deviceIds will only fetch transmissions for those devices
    // otherwise it grabs all devices for the selected group
    // The group is currently needed because we have to grab timezone and temp units
    async getDataTransmissions(deviceIds = [], start, end) {
      const makeApiCall = async (args) => {
        this.fetchingTransmissions = true;
        API.getTransmissions(args)
          .then(async (response) => {
            this.fetchingTransmissions = false;
            const {
              TemperatureUnits: tempUnit,
              TimeZone: timeZone,
              isMetric,
            } = groupsStore.selectedGroup;
            const dataTransmissions = cloneDeep(this.dataTransmissions);
            for (let deviceId in response) {
              dataTransmissions[deviceId] = await transformDataTransmissions(
                response[deviceId],
                {
                  timeZone,
                  tempUnit,
                  isMetric,
                }
              );
            }
            this.dataTransmissions = dataTransmissions;
          })
          .catch((error) => {
            if (error.status === 404) {
              this.transmissionsError =
                "Failed to fetch data. Please try again later.";
              this.fetchingTransmissions = false;
              return;
            } else {
              this.fetchingTransmissions = false;
              this.transmissionsError = error;
            }
          });
      };
      const buildApiCallArgs = () => {
        const args = {
          ids,
        };
        if (start) {
          args.start = start;
        } else {
          args.start = moment().subtract(8, "hours").toISOString();
        }
        if (end) {
          args.end = end;
        } else {
          args.end = moment().add(3, "hours").toISOString();
        }
        return args;
      };

      const groupsStore = useGroupsStore();
      if (!groupsStore.selectedGroupId) {
        return;
      }
      let ids = deviceIds;
      if (ids.length === 0) {
        ids = this.filteredLocations.map((d) => d.ID.toString());
      }
      // If there are no devices, don't make the request
      if (ids.length === 0) {
        return;
      }
      const args = buildApiCallArgs();

      for (let i = 0; i < ids.length; i += BATCH_SIZE_LIMIT_TRANSMISSIONS) {
        const batchIds = ids.slice(i, i + BATCH_SIZE_LIMIT_TRANSMISSIONS);
        const batchArgs = { ...args, ids: batchIds };
        await makeApiCall(batchArgs);
      }
    },
    async getMostRecentTransmissions(deviceIds = []) {
      const groupsStore = useGroupsStore();
      if (!groupsStore.selectedGroupId) {
        return;
      }
      let ids = deviceIds;
      if (ids.length === 0) {
        ids = this.filteredLocations.map((d) => d.ID.toString());
      }
      // If there are no devices, don't make the request
      if (ids.length === 0) {
        return;
      }
      this.fetchingTransmissions = true;
      try {
        const response = await API.getMostRecent(ids);
        this.fetchingTransmissions = false;
        const {
          TemperatureUnits: tempUnit,
          TimeZone: timeZone,
          isMetric,
        } = groupsStore.selectedGroup;
        const mostRecentTransmissions = cloneDeep(this.mostRecentTransmissions);
        for (let deviceId in response) {
          const DataTransmission = response[deviceId];
          mostRecentTransmissions[deviceId] = await transformMostRecentData(
            DataTransmission,
            {
              timeZone,
              tempUnit,
              isMetric,
            }
          );
        }
        this.mostRecentTransmissions = mostRecentTransmissions;
      } catch (error) {
        this.fetchingTransmissions = false;
        this.transmissionsError = error;
      }
    },
    getLocation(deviceId) {
      const groupStore = useGroupsStore();
      if (!deviceId || !groupStore.selectedGroupId) {
        return;
      }
      API.getLocation(deviceId).then((response) => {
        const groupsStore = useGroupsStore();
        const { TemperatureUnits: tempUnit, TimeZone: timeZone } =
          groupsStore.selectedGroup;
        const location = response;
        location.TimeZone = timeZone;
        const groupId = location.GroupID;
        let found = false;
        this.locations = {
          ...this.locations,
          [groupId]: {
            ...this.locations[groupId],
            Zones: this.locations[groupId].Zones.map((zone) => {
              return {
                ...zone,
                Locations: zone.Locations.map((l) => {
                  if (l.ID === location.ID) {
                    found = true;
                    return location;
                  }
                  return l;
                }),
              };
            }),
          },
        };
        if (!found) {
          console.log("Location not found");
        }
      });
    },
    async getGlanceData(groupID, type) {
      const groupsStore = useGroupsStore();
      const baseStore = useBaseStore();
      const priorityType = type || baseStore.preferences.glanceSelectedKey;
      const id = groupID || groupsStore.selectedGroupId;
      if (!priorityType || !id) {
        return;
      }
      try {
        const response = await API.getPriorityItems(id, priorityType);
        this.glanceItems = reduce(
          response,
          (acc, curr, id) => {
            acc[id] = {
              ...curr,
              ComputerVision: cleanComputerVisionData(curr.ComputerVision),
            };
            return acc;
          },
          {}
        );
      } catch (error) {
        console.error(error);
      }
    },
    async startWork(deviceId) {
      API.startWork(deviceId).then((response) => {
        if (response && response.Message === "SUCCESS") {
          DM.alertShow(
            `The work event was successfully started and has captured a photo/reading prior to beginning work. 
                              You may now begin your work and once finished, please click "End" Work to complete the work event and a final photo/reading will be captured for proof of work.`,
            "Begin Work Event Success"
          );
          this.startedWorkDeviceIds = [...this.startedWorkDeviceIds, deviceId];
        } else {
          DM.alertShow(
            "Start Work Event request wasn't successful. Please try again",
            "Begin Work Event Failed"
          );
        }
      });
    },
    async endWork(deviceId) {
      API.endWork(deviceId).then((response) => {
        if (response && response.Message === "SUCCESS") {
          DM.alertShow(
            `The work event was successfully completed and will capture a photo/reading for proof of work which can take several minutes to download. Once downloaded, the proof of work can be viewed in the Requests tab under History.`,
            "End Work Event Success"
          );
          this.startedWorkDeviceIds = this.startedWorkDeviceIds.filter(
            (id) => id !== deviceId
          );
        } else {
          DM.alertShow(
            "End Work Event request wasn't successful. Please try again",
            "End Work Event Failed"
          );
        }
      });
    },
    requestPhoto(deviceId) {
      return new Promise((resolve, reject) => {
        API.requestPhoto(deviceId).then((response) => {
          if (response && response.allowPhoto === 0) {
            DM.alertShow(
              `Photos can only be requested after waiting ${response.waitMinutes} minutes from the last request`,
              "Request Photo Failed"
            );
            reject();
          }
          if (response && response.allowPhoto === 1) {
            DM.alertShow(
              "The photo was successfully requested and will take a couple minutes to download.",
              "Request Photo Success"
            );
            return resolve();
          }
        });
      });
    },
    async triggerDefrost(deviceId) {
      API.triggerDefroster(deviceId).then((response) => {
        if (response && response.connected) {
          DM.alertShow(
            "The defrost cycle was successfully started and will take up to 5 minutes to complete.",
            "Defrost Success"
          );
        } else {
          DM.alertShow(
            "Defrost request wasn't successful. Please try again",
            "Defrost Failed"
          );
        }
      });
    },
  },
});
