// Need to be cancellable
// The catch for when the call is cancelled should swallow the cancellation error
// and not show the alert
// Any grouped together calls should use the same cancel controller
// Loading should be handled differently than we are currently doing it
// I want to be able to start showing a spinner and only hide it on successful load or error
// All calls should return promises

export async function retryRequest(apiRequest, retryOptions = {}) {
  const { maxRetries, delay, retryStatusCodes } = {
    maxRetries: 5,
    delay: 1000,
    retryStatusCodes: [500, 501, 502, 503, 504],
    ...retryOptions,
  };
  let attempts = 0;
  let delayTime = delay;

  while (attempts < maxRetries) {
    try {
      const response = await apiRequest();
      return response; // Return the response if successful
    } catch (error) {
      attempts++;
      if (attempts >= maxRetries || !retryStatusCodes.includes(error?.status)) {
        throw error; // Rethrow the error if retries are exhausted or status code is not in the list
      }
      await new Promise((resolve) => setTimeout(resolve, delayTime));
      delayTime *= 2; // Double the delay time for each retry
    }
  }
}

export default class API {
  getUserById(userId, cancelSignal) {
    return retryRequest(() =>
      DM.http({
        method: "GET",
        url: `/users/${userId}`,
        signal: cancelSignal,
      })
    );
  }
  getUsers(params, cancelSignal) {
    return retryRequest(() =>
      DM.http({
        method: "GET",
        url: "/users",
        params: params,
        signal: cancelSignal,
      })
    );
  }
  getGroups(cancelSignal) {
    return retryRequest(() =>
      DM.http({
        method: "GET",
        url: "/groups",
        signal: cancelSignal,
      })
    );
  }
  // Locations
  getLocation(deviceId, params, cancelSignal) {
    return retryRequest(() =>
      DM.http(
        {
          method: "GET",
          url: `/locations/${deviceId}`,
          params,
          signal: cancelSignal,
        },
        true,
        true
      )
    );
  }

  getLocations(
    searchText,
    selectedGroupId,
    isRefresh,
    hideAlert,
    cancelSignal,
    includeInactive = false
  ) {
    return retryRequest(() =>
      DM.http(
        {
          method: "GET",
          url: `/locations`,
          signal: cancelSignal,
          params: {
            search: searchText,
            groupId: selectedGroupId,
            includeTransmissions: false,
            includeForecasts: false,
            includeInactive,
          },
        },
        isRefresh,
        hideAlert
      )
    );
  }

  getPhotosMetadata(deviceId, params, cancelSignal) {
    return retryRequest(() =>
      DM.http(
        {
          method: "GET",
          url: `/locations/${deviceId}/dataTransmissions/metadata`,
          params,
          signal: cancelSignal,
        },
        true,
        true
      )
    );
  }

  getPhotos(deviceId, params, cancelSignal) {
    // params example:
    // start=2024-03-10T19%3A12%3A59.203Z
    // end=2024-03-11T19%3A12%3A59.203Z
    // limit=10
    // offset=0
    return retryRequest(() =>
      DM.http(
        {
          method: "GET",
          url: `/locations/${deviceId}/dataTransmissions/imageIncluded`,
          params,
          signal: cancelSignal,
        },
        true,
        true
      )
    );
  }

  getMostRecent(deviceIds, cancelSignal) {
    return retryRequest(() =>
      DM.http(
        {
          method: "GET",
          url: `/locations/dataTransmissions/mostRecent`,
          signal: cancelSignal,
          params: {
            locationIDs: deviceIds.join(","),
          },
        },
        true,
        true
      )
    );
  }

  getRequests(deviceId, cancelSignal) {
    return retryRequest(() =>
      DM.http(
        {
          method: "GET",
          url: `/devices/${deviceId}/requests`,
          signal: cancelSignal,
        },
        true,
        true
      )
    );
  }
  // Data transmissions
  // includesPhotosOnly=true is a boolean that will only return transmissions with photos and is limited to 4 days
  // includesPhotosOnly=false or missing, will return all transmissions within the last 4 days
  getTransmissions(
    { ids, includesPhotosOnly, start, end, interval },
    cancelSignal
  ) {
    let locationIDs = ids;
    if (!Array.isArray(locationIDs)) {
      locationIDs = [locationIDs];
    }
    const params = {
      locationIDs: locationIDs.join(","),
    };
    if (includesPhotosOnly) {
      params.includesPhotosOnly = includesPhotosOnly;
    }
    if (start) {
      params.start = start;
    }
    if (end) {
      params.end = end;
    }
    if (interval) {
      params.interval = interval;
    }
    params.apiVersion = 2;
    return retryRequest(() =>
      DM.http(
        {
          method: "GET",
          url: `/locations/dataTransmissions`,
          signal: cancelSignal,
          params,
        },
        true,
        true
      )
    );
  }

  // At a glance items
  getPriorityItems(groupID, type = "frostVision", cancelSignal) {
    if (!groupID) {
      return Promise.reject("No group ID provided");
    }
    return retryRequest(() =>
      DM.http(
        {
          method: "GET",
          url: `/locations/priority`,
          signal: cancelSignal,
          params: {
            groupId: groupID,
            type,
          },
        },
        true,
        true
      )
    );
  }

  // Forecasts
  getForecasts(locationIDs, groupID, cancelSignal) {
    if (!Array.isArray(locationIDs)) {
      locationIDs = [locationIDs];
    }
    return retryRequest(() =>
      DM.http(
        {
          method: "GET",
          url: `/locations/forecasts`,
          signal: cancelSignal,
          params: {
            locationIDs: locationIDs.join(","),
            groupID,
          },
        },
        true,
        true
      )
    );
  }

  // Alerts
  getAlerts(selectedGroupId, cancelSignal) {
    return retryRequest(() =>
      DM.http(
        {
          method: "GET",
          url: `/locations/activeAlerts`,
          signal: cancelSignal,
          params: {
            groupId: selectedGroupId,
          },
        },
        true
      )
    );
  }

  triggerDefroster(deviceId, cancelSignal) {
    return retryRequest(() =>
      DM.http({
        method: "POST",
        url: `/devices/${deviceId}/defrost`,
        signal: cancelSignal,
      })
    );
  }

  startEvent(deviceId, cancelSignal) {
    return retryRequest(() =>
      DM.http({
        method: "POST",
        url: `/devices/${deviceId}/startEvent`,
        signal: cancelSignal,
      })
    );
  }

  requestPhoto(deviceId, cancelSignal) {
    return retryRequest(() =>
      DM.http({
        method: "POST",
        url: `/devices/${deviceId}/updatePhoto`,
        signal: cancelSignal,
      })
    );
  }

  startWork(deviceId, cancelSignal) {
    return retryRequest(() =>
      DM.http({
        method: "POST",
        url: `/devices/${deviceId}/startWork`,
        signal: cancelSignal,
      })
    );
  }

  endWork(deviceId, cancelSignal) {
    return retryRequest(() =>
      DM.http({
        method: "POST",
        url: `/devices/${deviceId}/endWork`,
        signal: cancelSignal,
      })
    );
  }

  postFrostVisionFeedback(data, cancelSignal) {
    return retryRequest(() =>
      DM.http({
        method: "POST",
        url: `/computer-vision/feedback`,
        signal: cancelSignal,
        data,
      })
    );
  }

  getDeviceByVendorId(vendorDeviceId, cancelSignal) {
    return retryRequest(() =>
      DM.http(
        {
          method: "GET",
          url: `/devices`,
          signal: cancelSignal,
          params: {
            vendorDeviceID: vendorDeviceId,
          },
        },
        true,
        true
      )
    );
  }

  getDevicesByGroupId(groupId) {
    return retryRequest(() =>
      DM.http({
        method: "GET",
        url: `/devices`,
        params: {
          groupID: groupId,
        },
      })
    );
  }

  getDevice(deviceId, cancelSignal) {
    return retryRequest(() =>
      DM.http(
        {
          method: "GET",
          url: `/devices/${deviceId}`,
          signal: cancelSignal,
        },
        true,
        true
      )
    );
  }

  updateDevice(deviceId, data, cancelSignal) {
    return retryRequest(() =>
      DM.http(
        {
          method: "PUT",
          url: `/devices/${deviceId}`,
          signal: cancelSignal,
          data,
        },
        true,
        true
      )
    );
  }

  patchReplaceDevice(existingId, newDeviceVendorDeviceId) {
    return retryRequest(() =>
      DM.http({
        method: "PATCH",
        url: `/devices/${existingId}/replaceDevice`,
        data: {
          ReplacementVendorDeviceID: newDeviceVendorDeviceId,
        },
      })
    );
  }

  runDiagnostics(deviceId) {
    return retryRequest(() =>
      DM.http(
        {
          method: "GET",
          url: `/devices/${deviceId}/diagnostics`,
        },
        true,
        true
      )
    );
  }

  saveInstallParams(deviceId, data) {
    return retryRequest(() =>
      DM.http({
        method: "PUT",
        url: `/devices/${deviceId}/install`,
        data,
      })
    );
  }

  getDeviceInformation(deviceId) {
    return retryRequest(() =>
      DM.http({
        method: "GET",
        url: `/devices/${deviceId}/info`,
      })
    );
  }

  getSurfaceTypes() {
    return retryRequest(() =>
      DM.http(
        {
          method: "GET",
          url: `/devices/surfaceTypes`,
        },
        true
      )
    );
  }

  getLocationTypes() {
    return retryRequest(() =>
      DM.http(
        {
          method: "GET",
          url: `/devices/locationTypes`,
        },
        true
      )
    );
  }

  getLastPhoto(deviceId) {
    return retryRequest(() =>
      DM.http(
        {
          method: "GET",
          url: `/devices/${deviceId}/lastImage`,
        },
        true
      )
    );
  }
  // This finalizes the device setup and sends an email to the user
  sendNotification(deviceId) {
    return DM.http(
      {
        method: "POST",
        url: `/devices/${deviceId}/notification`,
      },
      true,
      true
    );
  }
}
