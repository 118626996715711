<template>
    <div class="flex flex-row sensor-forecast-section mr-3 ml-3">
        <div v-if="props.snowDepth" class="forecast-section snow-depth-section">
            <div class="forecast-block">
                <img class="forecast-tile-icon" :src="snowDepthIcon" alt="Snow Depth Icon" />
                <div class="forecast-text-block">
                    <span>Snow Depth</span>
                    <span>{{ props.snowDepth.snowDepthText !== 'null in' ? props.snowDepth.snowDepthText : '-' }}</span>
                </div>
            </div>
            <div class="forecast-text">
                <span class="uppercase mr-2">Read:</span>
                <span class="bold right">{{ props.snowDepth.timeSinceRead }}</span>
            </div>
        </div>
    </div>
</template>

<script setup>

import { defineProps } from 'vue';

import snowDepthIcon from '../../images/snow-depth-icon.svg';



const props = defineProps({
    isMetric: {
        type: Boolean,
        required: true
    },
    timeZone: {
        type: String,
        required: true
    },
    snowDepth: {
        type: Object,
        required: false
    },
});

console.log(props)

</script>