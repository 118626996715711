<template>
  <div>
    <div class="form-group">
      <label for="address">Address</label>
      <div class="flex-row">
        <input id="address" type="text" name="address" placeholder="Search for address" class="input-grouped"
          v-model="address" />
        <button @click="searchAddress" type="button" class="btn btn-secondary btn-grouped"><i class="fa fa-search"></i>
          <span class="ml-2">Search</span>
        </button>
      </div>
    </div>
    <div class="flex-row mt-2 space-between">
      <div class="form-group">
        <label for="latitude">Latitude</label>
        <input id="latitude" step=".0001" type="number" name="lat" placeholder="Latitude" class="input-grouped"
          :value="lat" @blur="handleBlur" max="90" min="-90" />
      </div>
      <div class="form-group">
        <label for="longitude">Longitude</label>
        <input id="longitude" step=".0001" type="number" name="lng" placeholder="Longitude" class="input-grouped"
          :value="lng" @blur="handleBlur" max="180" min="-180" />
      </div>
    </div>
    <div>
      <div ref="mapElement" class="app-google-maps-container"></div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineEmits, ref, onMounted, onUnmounted, computed, watch, nextTick, onUpdated } from 'vue';
import app from '../../js/app';
import { round } from 'lodash';

const props = defineProps({
  position: Object,
});

const emit = defineEmits([
  'handlePositionUpdate',
]);

const address = ref('');
const mapElement = ref(null);
const map = ref(null);
const marker = ref(null);

const lat = computed(() => round(props.position.lat, 4));
const lng = computed(() => round(props.position.lng, 4));

onUpdated(() => {
  if (map.value) {
    map.value.panTo(props.position);
    marker.value.position = props.position;
  }
})

function handleBlur(event) {
  if (event.target.name === 'lat') {
    emit('handlePositionUpdate', 'lat', parseFloat(event.target.value));
  } else {
    emit('handlePositionUpdate', 'lng', parseFloat(event.target.value));
  }
  nextTick(() => {
    map.value.panTo(props.position);
    marker.value.position = props.position;
    map.value.zoom = 15;
  });
}

function allowLocationServices() {
  return new Promise(app.getUserPosition)
    .then((position) => {
      return {
        lat: position.latitude,
        lng: position.longitude,
      };
    })
    .catch((_error) => {
      return Promise.reject();
    });
}

async function getCenterLocation() {
  allowLocationServices()
    .then((position) => {
      emit('handlePositionUpdate', 'position', position);
    })
    .catch(() => {
      console.log('Location services not allowed');
    });
}

async function initMap() {
  const { Map } = await google.maps.importLibrary("maps");
  const newMap = new Map(mapElement.value, {
    center: props.position,
    zoom: 15,
    mapTypeId: "roadmap",
    panControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    clickableIcons: false,
    mapId: "4504f8b37365c3d0", // Shown in the docs
  });
  map.value = newMap;
  createMarker(newMap);
}

async function createMarker(map) {
  const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
  marker.value = new AdvancedMarkerElement({
    position: props.position,
    map,
  });
  map.setCenter(props.position);
  map.addListener("center_changed", () => {
    updateMarkerPosition({
      lat: map.center.lat(),
      lng: map.center.lng(),
    });
  });
  map.addListener("click", (event) => {
    updateMarkerPosition({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  });
}

function updateMarkerPosition(position) {
  marker.value.position = position;
  // Check if positions are equal
  if (position.lat === props.position.lat && position.lng === props.position.lng) {
    return;
  }
  emit('handlePositionUpdate', 'position', {
    lat: position.lat,
    lng: position.lng,
  });
}

async function searchAddress() {
  const geocoder = new google.maps.Geocoder();
  geocoder.geocode({ address: address.value }, (results, status) => {
    if (status === "OK") {
      const position = {
        lat: results[0].geometry.location.lat(),
        lng: results[0].geometry.location.lng(),
      };
      updateMarkerPosition(position);
      map.value.setCenter(position);
    } else {
      console.log("Geocode was not successful for the following reason: " + status);
    }
  });
}

onMounted(() => {
  if (!window.google) {
    window.mapScript = document.createElement("script");
    window.mapScript.src =
      "https://maps.google.com/maps/api/js?v=3.59&key=" +
      process.env.VUE_APP_GOOGLE_MAP_API_KEY;

    document.body.appendChild(window.mapScript);
    window.mapScript.addEventListener("load", () => {
      getCenterLocation().then(() => {
        setTimeout(() => {
          initMap();
        });
      });
    });
  } else {
    getCenterLocation().then(() => {
      setTimeout(() => {
        initMap();
      });
    });
  }
})

onUnmounted(() => {
  if (map.value) {
    map.value.clearListeners();
  }
  window.mapScript.removeListeners();

})

</script>