<template>
    <div class="dm-flex-column dm-margin-sm">
        <div class="dm-flex-column dm-padding-top-md overflow-hidden">
            <ejs-chart width="99%" height="280px" border="{color: '#FFF'}" :primaryXAxis="primaryXAxis"
                :crosshair="crosshair" :indicators="indicators" :axes="axes" :palettes="palettes" :tooltip="tooltip">
                <e-series-collection>
                    <e-series :dataSource="history" type="Line" xName="chartDate" yName="depth" name="SnowDepth"
                        yAxisName="primaryXAxisDepth" width="2" />
                </e-series-collection>
            </ejs-chart>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapStores, mapState } from 'pinia';
import { useBaseStore, useHistoryStore, useLocationsStore } from '../../js/store';
import {
    CandleSeries,
    Category,
    Tooltip,
    DateTime,
    Zoom,
    Logarithmic,
    Crosshair,
    LineSeries,
    AccumulationDistributionIndicator,
    StripLine,
    ColumnSeries,
    ScatterSeries,
} from "@syncfusion/ej2-vue-charts";

export default {
    name: 'snowdepth-history-chart',
    props: [
        "history",
        "isMetric",
    ],
    provide: {
        chart: [
            CandleSeries,
            Category,
            LineSeries,
            Tooltip,
            DateTime,
            StripLine,
            Zoom,
            Logarithmic,
            Crosshair,
            AccumulationDistributionIndicator,
            ColumnSeries,
            ScatterSeries
        ]
    },
    data: function () {
        return {
            lblTitle: '',
            componentKey: 0,
            primaryXAxisDepth: {
                visible: true,
                labelFormat: '{value}',
            },
            marker: {
                height: 6,
                width: 6,
                fill: '#319cf4',
                dataLabel: {
                    visible: false
                }
            },
            crosshair: { enable: true, lineType: 'Vertical' },
            tooltip: {
                enable: true,
                shared: true,
                format: "${series.name}: <b>${point.y}</b>",
            },
        }
    },
    methods: {},
    computed: {
        ...mapStores(useBaseStore, useLocationsStore),
        ...mapState(useHistoryStore, ['start', 'end', 'interval']),
        palettes() {
            const colors = [
                '#999'
            ];
        },
        axes() {
            const maxValue = Math.max(...this.history.map((d) => d.depth));
            let interval = 10;
            if (this.isMetric) {
                if (maxValue > 1000) {
                    interval = 500;
                } else if (maxValue > 100) {
                    interval = 50;
                } else {
                    interval = 10;
                }
            } else {
                if (maxValue > 40) {
                    interval = 24;
                } else if (maxValue > 10) {
                    interval = 12;
                } else {
                    interval = 4;
                }
            }

            return [
                {
                    name: "primaryXAxisDepth",
                    labelFormat: "{value}" + (this.isMetric ? " mm" : " in"),
                    labelStyle: {
                        size: "12px",
                    },
                    minimum: 0,
                    interval,
                    majorGridLines: { color: "#e9e9e9" },
                    majorTickLines: { color: "#e9e9e9" },
                },
            ];
        },
        primaryXAxis() {
            let stripLine = {};
            return {
                visible: true,
                color: '#5fb1f6',
                valueType: 'DateTime',
                labelFormat: 'MMM d h:mm a',
                intervalType: 'Hours',
                stripLines: [stripLine]
            }
        },
    },
}

</script>