import { defineStore } from "pinia";
import { sortBy } from "lodash";
import Fuse from "fuse.js";

import api from "../api";
import { useBaseStore } from "./base";
import { useLocationsStore } from "./locations";
import { useHistoryStore } from "./history";

const API = new api();

// Groups store
export const useGroupsStore = defineStore("groups", {
  state: () => ({
    groups: [],
    selectedGroupId: null,
    groupOptions: [{ label: "Loading...", id: null }],
    fetchingGroups: false,
    activeAlerts: [],
    searchIndex: null,
  }),
  getters: {
    selectedGroup() {
      const id = this.selectedGroupId;
      if (!id) {
        return {};
      }
      return this.groups.find((g) => g.ID === id) || {};
    },
    selectedGroupOption() {
      const id = this.selectedGroupId;
      if (!id) {
        return { label: "Loading...", id: null };
      }
      return {
        id: this.selectedGroup.ID,
        label: this.selectedGroup.Name,
      };
    },
    tempUnit() {
      const TemperatureUnits = this.selectedGroup.TemperatureUnits;
      if (TemperatureUnits === "Fahrenheit") {
        return "F";
      }
      if (TemperatureUnits === "Celsius") {
        return "C";
      }
      return null;
    },
    metricUnits() {
      return this.selectedGroup.TemperatureUnits === "Celsius";
    },
    timeZone() {
      if (!this.selectedGroup) {
        return null;
      }
      return this.selectedGroup.TimeZone;
    },
  },
  actions: {
    async getGroups() {
      // Only fetch groups if we aren't in the process of fetching them
      // if (this.fetchingGroups) {
      //   return
      // }
      this.fetchingGroups = true;
      const response = await API.getGroups();
      this.groups = response.map((group) => {
        return {
          ...group,
          isMetric: group.TemperatureUnits.toLowerCase() === "celsius",
        };
      });
      this.fetchingGroups = false;
      this.groupOptions = response.map((g) => {
        return {
          label: g.Name,
          id: g.ID,
        };
      });
      this.groupOptions = sortBy(this.groupOptions, "label");
      this.searchIndex = new Fuse(this.groupOptions, {
        keys: ["label"],
      });
    },
    async selectGroupFromPreferences(groupId) {
      const baseStore = useBaseStore();
      if (this.groups.length === 0) {
        await this.getGroups();
      }
      if (groupId) {
        this.selectGroup(groupId);
      } else if (baseStore.defaultGroupId) {
        // Does the default group exist within the groups
        // We have run into an issue where default groups exist that the user does
        // not have access to.
        const defaultGroup = this.groups.find(
          (g) => g.ID === baseStore.defaultGroupId
        );
        if (defaultGroup) {
          this.selectGroup(baseStore.defaultGroupId);
        } else {
          this.selectFirstGroup();
        }
      } else if (this.selectedGroupId === null) {
        this.selectFirstGroup();
      }
    },
    async selectGroup(groupId) {
      if (this.groups.length === 0) {
        await this.getGroups();
      }
      if (groupId === this.selectedGroupId) {
        return;
      }
      this.selectedGroupId = groupId;
      const locationsStore = useLocationsStore();
      locationsStore.getGroupLocations(false, true);

      const historyStore = useHistoryStore();
      historyStore.updateStartWithTimezone(this.selectedGroup.TimeZone);

      this.getActiveAlerts();
    },
    selectFirstGroup() {
      const id = this.groupOptions[0].id;
      this.selectGroup(id);
    },
    getActiveAlerts() {
      const groupsStore = useGroupsStore();
      if (!groupsStore.selectedGroupId) {
        return;
      }

      this.clearActiveAlerts();
      API.getAlerts(this.selectedGroupId).then((response) => {
        this.activeAlerts = response.map((alert) => {
          return {
            MapPinColor: alert.MapPinColor,
            AlertID: alert.AlertID,
            DeviceID: alert.DeviceID,
            GroupID: alert.GroupID,
          };
        });
      });
    },
    clearActiveAlerts() {
      this.activeAlerts = [];
    },
  },
});
