<template>
    <div class="flex-col">
        <forecast-section :forecast="forecasts" :time-zone="sensor?.TimeZone" :snow-depth="snowDepth"
            :temperature-unit="temperatureUnit" :is-metric="isMetric"></forecast-section>
        <div class="dm-flex-row dm-padding-md sensor-header">
            <div class="dm-flex-column dm-padding-right-md sensor-meta-and-tools">
                <div class="desktop-sensor-tools">
                    <sensor-tools :sensor-id="sensorId" :status="status" @handle-status-update="handleStatusUpdate"
                        :user-has-full-access="userHasFullAccess"
                        :wrapper-classes="{ 'dm-flex-column': true }"></sensor-tools>
                </div>
            </div>
            <carousel :image-transmissions="imageTransmissions" :temperature-unit="temperatureUnit"
                :sensor-id="sensorId" :is-metric="isMetric">
            </carousel>
        </div>
        <div class="chart-wrapper">
            <sensor-chart-component :key="sensor?.ID" chartKey="sensorView" :sensor="sensor" chartHeight="200px"
                :data-transmissions="dataTransmissions || []"
                :most-recent-transmission="mostRecent?.MostRecentDataTransmission"
                :road-conditions-history="forecasts?.RoadConditionsHistory || []"
                :forecast-data="forecasts?.ForecastData || []"
                :legendSettings="{ visible: true, toggleVisibility: true, position: 'Bottom' }" />

        </div>

        <sensor-history-section :device-id="sensorId" :history="history.transmissions || []" :requests="requests"
            :road-conditions-history="history.roadConditions || []"
            :temperature-unit="temperatureUnit"></sensor-history-section>
    </div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment-timezone';
import { mapStores } from 'pinia';

import { useBaseStore, useForecastsStore, useGroupsStore, useHistoryStore, useLocationsStore } from '../../js/store';
import app from '../../js/app';
import { convertWindSpeed, formatWindDirection, parseWindSpeedUnit, isTabletScreen } from '../../js/utils';

import sensorTools from './sensorTools';
import sensorDetails from '../sensorDetails';
import sensorHistorySection from './sensorHistorySection';
import fallbackImage from '../fallbackImageComponent';
import ForecastTiles from "../forecastTiles";
import imageWithOverlay from '../imageWithOverlay';
import forecastIcons from '../forecastIcons';
import carousel from './carousel';
import forecastSection from './forecastSection'
import sensorChartComponent from '../sensorChartComponent.vue';

const DEFAULT_STATUS = {
    defrost: false,
    work: false,
    photo: false
}

export default {
    name: 'rwis-sensor-view',
    props: ['sensorId'],
    components: {
        sensorTools,
        sensorDetails,
        sensorHistorySection,
        fallbackImage,
        ForecastTiles,
        imageWithOverlay,
        forecastIcons,
        carousel,
        forecastSection,
        sensorChartComponent,
    },

    data: function () {
        return {
            refreshTimer: null,
            status: { ...DEFAULT_STATUS },
        };
    },
    methods: {
        convertWindSpeed,
        formatWindDirection,
        parseWindSpeedUnit,

        async getSensorById() {
            // If we don't have a sensor ID, we can't do anything, redirect to dashboard
            if (!this.sensorId) {
                this.$router.push({
                    name: 'sensors',
                    query: {
                        ...this.$route.query,
                        sensorId: undefined
                    }
                });
                return;
            }
            if (this.groupsStore.groups.length === 0 || !this.groupsStore.selectedGroup) {
                const groupId = this.$route.query.groupId;
                await this.groupsStore.selectGroupFromPreferences(groupId);
            }
            // Get the image transmissions for the sensor
            await this.locationsStore.getImageTransmissions(this.sensorId);
            // We should retrieve images if we have none
            // Current images gets images for all sensors in the group
            if (!this.locationsStore.mostRecent[this.sensorId]) {
                await this.locationsStore.getMostRecentTransmissions();
            }
            // Once we have a way to include images in the data transmissions
            await this.locationsStore.getDataTransmissions([this.sensorId]);
            // We should also retrieve forecasts either way so they are fresh
            await this.forecastsStore.getForecasts([this.sensorId]);

            await this.getHistory();
            await this.getPhotos();
            await this.getRequests();
        },
        async getHistory() {
            if (!this.sensorId) {
                return;
            }
            await this.historyStore.getHistory(this.sensorId);
        },
        async getPhotos() {
            if (!this.sensorId) {
                return;
            }
            await this.historyStore.getPhotosMetadata(this.sensorId);
            await this.historyStore.getPhotosPage(this.sensorId);
        },
        async getRequests() {
            if (!this.sensorId) {
                return;
            }
            await this.historyStore.getRequests(this.sensorId);
        },

        handleStatusUpdate(key, status) {
            this.status[key] = status;
        },
    },
    computed: {
        ...mapStores(useBaseStore, useGroupsStore, useLocationsStore, useHistoryStore, useForecastsStore),
        userHasFullAccess() {
            return true; // Add logic to determine user access
        },
        sensor() {
            const found = this.locationsStore.filteredLocations.find(l => {
                return parseInt(l.ID, 10) === parseInt(this.sensorId, 10)
            });
            if (!found) {
                return {};
            }
            return found
        },
        isMetric() {
            if (this.groupsStore.metricUnits !== null && this.groupsStore.metricUnits !== undefined) {
                return this.groupsStore.metricUnits;
            }
            return this.sensor?.GroupEmbedded?.IsMetric ? true : false;
        },
        temperatureUnit() {
            if (this.groupsStore.temperatureUnits !== null && this.groupsStore.temperatureUnits !== undefined) {
                return this.groupsStore.temperatureUnits === "Celsius" ? "°C" : "°F";
            }
            return this.sensor?.GroupEmbedded?.TemperatureUnits === "Fahrenheit" ? "°F" : "°C";
        },
        dataTransmissions() {
            // These should already be in the correct order
            return this.locationsStore.dataTransmissions[this.sensorId] || [];
        },
        mostRecent() {
            return this.locationsStore.mostRecentTransmissions[this.sensorId] || {};
        },
        mostRecentTransmission() {
            return this.mostRecent.MostRecentDataTransmission[this.sensorId] || {};
        },
        snowDepth() {
            const mostRecentSnowDepth = this.mostRecent?.MostRecentSnowDepthReading;
            if (!mostRecentSnowDepth || Object.keys(mostRecentSnowDepth).length === 0) {
                return null;
            }
            const sd = this.isMetric ? mostRecentSnowDepth?.MeasuredHeightMm + ' mm' : mostRecentSnowDepth?.MeasuredHeightInches + ' in';
            const timeSinceRead = moment(mostRecentSnowDepth?.TransmissionDateTimeUTC).fromNow();
            return {
                snowDepthText: sd,
                timeSinceRead,
            }
        },
        forecasts() {
            return this.forecastsStore.forecasts[this.sensorId] || {};
        },
        imageTransmissions() {
            // Server is returning some images that are not near transmission datetime
            const images = this.locationsStore.imageTransmissions[parseInt(this.sensorId, 10)] || [];
            return images.map(i => {
                return {
                    ...i.TransmissionData,
                    ComputerVision: i.ComputerVision,
                    chartDate: i.chartDate,
                    TransmissionDateTimeUTC: i.TransmissionDateTimeUTC,
                }
            });
        },
        history() {
            return {
                transmissions: this.historyStore.transmissions[this.sensorId] || [],
                roadConditions: this.historyStore.roadConditionsHistory[this.sensorId] || []
            };
        },
        requests() {
            return this.historyStore.requests[this.sensorId] || [];
        },
        isTablet() {
            return isTabletScreen(true)
        },
    },
    watch: {
        sensorId(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.getSensorById();
                this.transmissionIndex = 0;
                this.status = { ...DEFAULT_STATUS };
            }
        },
        'historyStore.end'() {
            this.getPhotos();
        },
    },
    mounted() {
        this.getSensorById();
        this.refreshTimer = setInterval(() => {
            this.getSensorById();
        }, 1000 * app.DASHBOARD_REFRESH_INTERVAL_SECONDS);

    },
    beforeDestroy() {
        clearInterval(this.refreshTimer);
    }
};
</script>