<template>
    <div class="dm-flex-col">
        <snowdepth-section :time-zone="sensor?.TimeZone" :snow-depth="snowDepth" :temperature-unit="temperatureUnit"
            :is-metric="isMetric"></snowdepth-section>
        <h3 class="dm-margin-left-sm text-lg">Historical</h3>
        <sensor-history-bar :device-id="sensorId" :v2="true"></sensor-history-bar>
        <snowdepth-history-chart :history="depths" :is-metric="isMetric"></snowdepth-history-chart>
    </div>
</template>

<script>
import Vue, { ref } from 'vue';
import moment from 'moment-timezone';
import { mapStores } from 'pinia';
import { useElementSize } from '@vueuse/core';

import { useBaseStore, useForecastsStore, useGroupsStore, useHistoryStore, useLocationsStore } from '../../js/store';
import app from '../../js/app';
import { convertWindSpeed, formatWindDirection, parseWindSpeedUnit, isTabletScreen } from '../../js/utils';

import sensorTools from './sensorTools';
import sensorDetails from '../sensorDetails';
import sensorHistorySection from './sensorHistorySection';
import fallbackImage from '../fallbackImageComponent';
import ForecastTiles from "../forecastTiles";
import imageWithOverlay from '../imageWithOverlay';
import forecastIcons from '../forecastIcons';
import carousel from './carousel';
import snowdepthSection from './snowdepthSection.vue';
import sensorHistoryBar from './sensorHistoryBar';
import snowdepthHistoryChart from './snowdepthHistoryChart';
import sensorHistoryTable from './sensorHistoryTable';

export default {
    name: 'snowdepth-sensors-view',
    props: ['sensorId'],
    emits: [
        'handle-go-back'
        ,],
    components: {
        sensorTools,
        sensorDetails,
        sensorHistorySection,
        fallbackImage,
        ForecastTiles,
        imageWithOverlay,
        forecastIcons,
        carousel,
        snowdepthSection,
        sensorHistoryBar,
        snowdepthHistoryChart,
        sensorHistoryTable,
    },
    setup: () => {
        const sensorWindow = ref(null);
        const { width, height } = useElementSize(sensorWindow);
        return {
            width,
            height,
            sensorWindow,
        };
    },
    data: function () {
        return {
            stacked: false,
            refreshTimer: null,
        };
    },
    methods: {
        convertWindSpeed,
        formatWindDirection,
        parseWindSpeedUnit,

        async getSensorById() {
            // If we don't have a sensor ID, we can't do anything, redirect to dashboard
            if (!this.sensorId) {
                this.$router.push({
                    name: 'sensors',
                    query: {
                        ...this.$route.query,
                        sensorId: undefined
                    }
                });
                return;
            }
            if (this.groupsStore.groups.length === 0 || !this.groupsStore.selectedGroup) {
                const groupId = this.$route.query.groupId;
                await this.groupsStore.selectGroupFromPreferences(groupId);
            }
            // We should retrieve images if we have none
            // Current images gets images for all sensors in the group
            if (!this.locationsStore.mostRecent[this.sensorId]) {
                await this.locationsStore.getMostRecentTransmissions();
            }
            // Once we have a way to include images in the data transmissions
            await this.locationsStore.getDataTransmissions([this.sensorId]);

            await this.getHistory();
        },
        async getHistory() {
            if (!this.sensorId) {
                return;
            }
            await this.historyStore.getHistoryV2(this.sensorId);
        },
    },
    computed: {
        ...mapStores(useBaseStore, useGroupsStore, useLocationsStore, useHistoryStore, useForecastsStore),
        userHasFullAccess() {
            return true; // Add logic to determine user access
        },
        sensor() {
            const found = this.locationsStore.filteredLocations.find(l => {
                return parseInt(l.ID, 10) === parseInt(this.sensorId, 10)
            });
            if (!found) {
                return {};
            }
            return found
        },
        isMetric() {
            return this.sensor?.GroupEmbedded?.IsMetric ? true : false;
        },
        temparatureUnit() {
            return this.sensor?.GroupEmbedded?.TemperatureUnits === "Fahrenheit" ? "°F" : "°C";
        },
        dataTransmissions() {
            // These should already be in the correct order
            return this.locationsStore.dataTransmissions[this.sensorId] || [];
        },
        mostRecent() {
            return this.locationsStore.mostRecentTransmissions[this.sensorId] || {};
        },
        snowDepth() {
            const mostRecentSnowDepth = this.mostRecent?.MostRecentSnowDepthReading;
            if (!mostRecentSnowDepth || Object.keys(mostRecentSnowDepth).length === 0) {
                return null;
            }
            const sd = this.isMetric ? mostRecentSnowDepth?.MeasuredHeightMm + ' mm' : mostRecentSnowDepth?.MeasuredHeightInches + ' in';
            const timeSinceRead = moment(mostRecentSnowDepth?.TransmissionDateTimeUTC).fromNow();
            return {
                snowDepthText: sd,
                timeSinceRead,
            }
        },
        history() {
            return {
                transmissions: this.historyStore.transmissions[this.sensorId] || [],
            };
        },
        depths() {
            return this.history.transmissions.map(t => {
                if (!t.chartDate || t.SnowDepthReading?.MeasuredHeightMm === undefined || t.SnowDepthReading?.MeasuredHeightMm === null) {
                    return null;
                }
                return {
                    chartDate: t.chartDate,
                    depth: this.isMetric ? t.SnowDepthReading.MeasuredHeightMm : t.SnowDepthReading.MeasuredHeightInches,
                }
            }).filter(d => d !== null);
        },
        isTablet() {
            return isTabletScreen(true)
        },
    },
    watch: {
        sensorId(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.getSensorById();
                this.transmissionIndex = 0;
                this.status = { ...DEFAULT_STATUS };
            }
        },
        width(newValue) {
            this.stacked = newValue < 700;
        },
    },
    mounted() {
        this.getSensorById();
        this.refreshTimer = setInterval(() => {
            this.getSensorById();
        }, 1000 * app.DASHBOARD_REFRESH_INTERVAL_SECONDS);

    },
    beforeDestroy() {
        clearInterval(this.refreshTimer);
    }
};
</script>