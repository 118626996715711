<template>
    <div></div>
</template>

<script setup>
// Check to see if we have the URL params we need for setup
import { useRouter, useRoute } from 'vue-router/composables';
import { useSetupStore } from '../../js/store';
import { onMounted } from 'vue';

const router = useRouter();
const route = useRoute();
const setupStore = useSetupStore();

onMounted(async () => {
    const { id, devicetype, producttype } = route.query;
    const dt = devicetype || producttype;
    if (dt?.toLowerCase().includes('sds')) {
        router.push({ name: 'mobile-apps' });
        return
    }
    await setupStore.getDeviceByVendorId(id);
    // Did we find a device?
    if (setupStore.setupDevice?.ID) {
        router.push({ name: 'setup-steps', params: { id, deviceType: dt, step: 1 } });
    } else {
        router.push({ name: 'scan-device' });
    }
})
</script>