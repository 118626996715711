<template>
	<div id="app">
		<div>
			<div v-if="!['login', 'forgot-password'].includes($route.name)"
				class="app-header dm-flex-row dm-align-items-center">
				<a href="/">
					<img class="app-header-logo dm-flex-none mr-2" src="./images/frost-logo-horizontal-black-new.png"
						alt="Frost Solutions" />
				</a>

				<div class="dm-flex-none app-mobile-hide">
					<div class="e-menu-wrapper e-lib e-keyboard">
						<ul class="e-control e-menu e-lib e-menu-parent">
							<li @click="handleNavigation('/')" class="e-menu-item"
								:class="{ 'e-selected': $route.name === 'sensors' }">
								Dashboard
							</li>
							<li @click="handleNavigation('/alerts')" class="e-menu-item"
								:class="{ 'e-selected': $route.path === '/alerts' }">
								Alerts
							</li>
							<li v-if="showSettings" @click="handleNavigation('/settings/groups')" class="e-menu-item"
								:class="{ 'e-selected': $route.path.includes('settings') }">
								Settings
							</li>
						</ul>
					</div>
				</div>

				<div class="dm-flex-1"></div>

				<div class="dm-flex-row dm-align-items-center app-mobile-hide" style="gap: 5px">
					<template v-if="!isMobile">
						<page-notification />
					</template>
					<ejs-menu class="hide-caret" :items="rightMenuItems" :select="handleMenuClick"></ejs-menu>
				</div>

				<div class="app-mobile-show-flex dm-flex-row dm-align-items-center" style="gap: 15px">
					<template v-if="isMobile">
						<page-notification />
					</template>
					<ejs-menu class="hide-caret" :items="mobileMenuItems" :select="handleMenuClick"></ejs-menu>
				</div>
			</div>

			<div class="dm-flex-column dm-flex-grow">
				<router-view />
			</div>
		</div>

		<div v-if="baseStore.modals.length > 0" class="dm-position-absolute">
			<component v-for="(modal, index) in baseStore.modals" v-if="baseStore.modals.length > 0" :key="index"
				:ref="modal.modalRefGuid" :modal-ref-guid="modal.modalRefGuid" :is="modal.modalComponent"
				:modal-data="modal.modalData" />
		</div>
		<portal-target name="modals" />
		<improve-modal />
		<device-not-found-modal />
	</div>
</template>

<script>
import Vue from 'vue';
import { mapStores } from 'pinia';
import { event } from 'vue-gtag';

import { useBaseStore } from "./js/store";

import PageNotification from './components/notifications/PageNotification.vue';
import usersEditModal from './components/modals/usersEditModal';
import usersPasswordEditModal from './components/modals/usersPasswordEditModal';
import groupsEditModal from './components/modals/groupsEditModal';
import locationsEditModal from './components/modals/locationsEditModal';
import alertsEditModal from './components/modals/alertsEditModal';
import qrCodeScanModal from './components/modals/qrCodeScanModal';
import filesAddModal from './components/modals/filesAddModal';
import fileAttachmentsModal from './components/modals/fileAttachmentsModal';
import gridListViewModal from './components/modals/gridListViewModal';
import sensorsPhotoModal from './components/modals/sensorsPhotoModal';
import improveModal from './components/modals/improveModal';
import deviceNotFoundModal from './components/modals/deviceNotFoundModal';

export default {
	name: 'app',
	components: {
		usersEditModal,
		usersPasswordEditModal,
		groupsEditModal,
		locationsEditModal,
		alertsEditModal,
		qrCodeScanModal,
		filesAddModal,
		fileAttachmentsModal,
		gridListViewModal,
		sensorsPhotoModal,
		PageNotification,
		improveModal,
		deviceNotFoundModal,
	},
	data: function () {
		return {
			rightMenuItems: [
				{
					text: '',
					iconCss: 'fa fa-user e-caret-hide',
					items: [
						{
							text: 'My Profile',
							onClick: this.handleProfileClick,
						},
						{
							text: 'Sign Out',
							onClick: this.handleSignOutClick,
						},
						{
							text: 'FAQ',
							onClick: () => this.handleExternalLink('https://frostsolutions.io/faq/'),
						},
					]
				}
			],
			supportMenuItems: [
				{
					text: 'Support',
					onClick: this.handleSupportClick,
					iconCss: 'fa fa-question-circle'
				},
			],
		}
	},
	computed: {
		...mapStores(useBaseStore),
		showSettings() {
			let isAdmin = this.baseStore.user?.Groups?.find(element => element?.Permission);
			if (this.baseStore.user?.IsSuperAdmin || isAdmin?.Permission) {
				return true;
			}

			return this.baseStore.Groups?.filter(g => g.Permission === 'Admin').length > 0;
		},
		mobileMenuItems() {
			const menuItems = [
				{
					text: '',
					iconCss: 'fa fa-bars',
					items: [
						{
							text: 'Dashboard',
							onClick: () => this.handleNavigation('/'),
						},
						{
							text: 'Alerts',
							onClick: () => this.handleNavigation('/alerts'),
						},
						{
							text: 'Settings',
							onClick: () => this.handleNavigation('/settings/groups'),
						},
						{
							text: 'My Profile',
							onClick: this.handleProfileClick,
						},
						{
							text: 'Sign Out',
							onClick: this.handleSignOutClick,
						},
						{
							text: 'FAQ',
							onClick: () => this.handleExternalLink('https://frostsolutions.io/faq/'),
						},
					]
				},
			];

			const menu = [
				{
					text: '',
					iconCss: 'fa fa-bars',
					items: menuItems[0].items
				},
			]

			if (!this.baseStore.user?.IsSuperAdmin && this.baseStore.user?.Groups && this.baseStore.user?.Groups.filter(g => g.Permission === 'Admin').length === 0) {
				menu[0].items = menuItems[0].items.filter(i => i.text !== 'Settings');
			}

			return menu;
		},
		isMobile() {
			return window.innerWidth < 666;
		},
	},
	methods: {
		handleExternalLink(link) {
			window.open(link, '_blank');
		},
		handleNavigation(path, name) {
			if (this.$route.path !== path) {
				this.$router.push(path);
			}
		},
		handleMenuClick(args) {
			if (args.item.onClick) {
				args.item.onClick();
			}
		},
		handleSignOutClick() {
			event('logout', {
				'user_id': this.baseStore.userID,
			});

			DM.http({
				method: "DELETE",
				url: "/users/auth-tokens",
			}).then(() => {
				this.resetUserToSignIn();
			});
		},
		handleSupportClick() {
			eventBus.$emit('showAppModal', this, 'supportModal', {});
		},
		handleProfileClick() {
			DM.http({
				method: "GET",
				url: `/users/${this.baseStore.user.ID}`,
			}).then(response => {
				this.baseStore.user = response;

				eventBus.$emit('showAppModal', this, 'usersEditModal', {
					mode: 'profile',
					user: response,
					callback: (user) => {
						this.baseStore.user = user;
					}
				});
			});
		},
		resetUserToSignIn() {
			this.baseStore.signOutUser();
		},
	},
	created() { },
	mounted: function () {
		if (this.baseStore.user.JobTitle === '') {
			this.handleProfileClick();
		}

		eventBus.$on('resetUserToSignIn', (userData) => {
			// This is called when a 401 is returned
			this.resetUserToSignIn();
		});

		eventBus.$on("showAppModal", (scope, modalComponentName, initData) => {
			if (initData === undefined || initData === null) {
				initData = {};
			}

			let modalObject = {
				modalRefGuid: DM.getRandomGuid(),
				modalComponent: modalComponentName,
				modalData: initData,
			};

			this.baseStore.modals.push(modalObject);
		});

		eventBus.$on("closeAppModal", (eventData) => {
			this.baseStore.removeModal(eventData);
		});
	}
}
</script>