import VueRouter from "vue-router";
import DashboardPage from "../components/pages/dashboardPage";
import AlertsPage from "../components/pages/alertsPage";
import SettingsPage from "../components/pages/settingsPage";
import SignInPage from "../components/signInPage";
import ForgotPasswordPage from "../components/forgotPasswordPage";

import GroupsPage from "../components/pages/groupsPage";
import LocationsPage from "../components/pages/locationsPage";
import UsersPage from "../components/pages/usersPage";
import MobileAppsPage from "../components/pages/mobileAppsPage";
import AddNewDeviceModal from "../components/modals/addNewDeviceModal.vue";
import ScanDevice from "../components/setup/ScanDevice.vue";
import Flow from "../components/setup/setupStep.vue";
import notFoundPage from "../components/pages/notFoundPage.vue";

import app from "./app";
import { useBaseStore, useSetupStore } from "./store";
import { set } from "vue-gtag";
import SetupPage from "../components/pages/setupPage.vue";

const authGuard = async (to, _from, next) => {
  let authToken = DM.localStorageGet(app.localStorageAuthTokenName);
  let userID = DM.localStorageGet(app.localStorageUserIDName);

  set({ user_id: userID });

  if (authToken && userID) {
    const store = useBaseStore();
    store.setAuthentication();

    store.getUserByID(userID).then(() => {
      if (to.name === "login") {
        next({ name: "sensors" });
      } else {
        next();
      }
    });
  } else if (to.name === "login") {
    next();
  } else if (to.name === "forgot-password") {
    next();
  } else {
    next("/login");
  }
};

const permissionGuard = (to, from, next) => {
  const store = useBaseStore();
  let isAdmin = store.user?.Groups?.find((element) => element?.Permission);
  if ((store.user && store.user.IsSuperAdmin) || (store.user && isAdmin)) {
    next();
  } else {
    next({ name: "sensors" });
  }
};

const router = new VueRouter({
  mode: "history",
  hash: false,
  routes: [
    {
      path: "/setup",
      component: SettingsPage,
      meta: {
        title: "Setup",
      },
      beforeEnter: authGuard,
      children: [
        {
          path: "",
          name: "setup",
          component: SetupPage,
        },
        {
          path: "add-device",
          name: "add-device",
          components: {
            modal: AddNewDeviceModal,
          },
          meta: {
            title: "Add Device",
          },
          children: [
            {
              path: "scan",
              name: "scan",
              meta: {
                title: "Scan Device",
              },
              component: ScanDevice,
            },
            {
              path: ":deviceType/:id/:step",
              name: "setup-steps",
              meta: {
                title: "Add Device",
              },
              component: Flow,
              props: (route) => ({
                deviceType: route.params.deviceType,
                id: route.params.id,
                step: parseInt(route.params.step, 10),
              }),
            },
          ],
        },
      ],
    },
    {
      path: "/mobile-apps",
      name: "mobile-apps",
      meta: {
        title: "Mobile Apps",
      },
      component: MobileAppsPage,
    },
    {
      path: "/",
      name: "sensors",
      meta: (route) => {
        if (route.query.sensorId) {
          return {
            title: "Sensor View",
          };
        }
        return {
          title: "Dashboard",
        };
      },
      beforeEnter: authGuard,
      component: DashboardPage,
      props: (route) => ({
        sensorId: route.query.sensorId,
        gridView: route.query.gridView === "true",
        groupId: route.query.groupId,
      }),
    },
    {
      path: "/alerts",
      name: "alerts",
      meta: {
        title: "Alerts",
      },
      beforeEnter: authGuard,
      component: AlertsPage,
    },
    {
      path: "/settings",
      name: "settings",
      meta: {
        title: "Settings",
      },
      beforeEnter: authGuard,
      component: SettingsPage,
      children: [
        {
          path: "groups",
          name: "groups",
          meta: {
            title: "Settings: Groups",
          },
          component: GroupsPage,
          beforeEnter: permissionGuard,
        },
        {
          path: "locations",
          name: "locations",
          meta: {
            title: "Settings: Locations",
          },
          component: LocationsPage,
          beforeEnter: permissionGuard,
        },
        {
          path: "users",
          name: "users",
          meta: {
            title: "Settings: Users",
          },
          component: UsersPage,
          beforeEnter: permissionGuard,
        },
      ],
    },
    {
      path: "/login",
      name: "login",
      meta: {
        title: "Login",
      },
      beforeEnter: authGuard,
      component: SignInPage,
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      meta: {
        title: "Forgot Password",
      },
      beforeEnter: authGuard,
      component: ForgotPasswordPage,
    },
    {
      path: "/:catchAll(.*)",
      name: "not-found",
      meta: {
        title: "Not Found",
      },
      component: notFoundPage,
    },
  ],
});

// Set the page title
router.beforeEach((to, from, next) => {
  let meta = to.meta;
  if (typeof to.meta === "function") {
    meta = to.meta(to);
  }
  if (meta.title) {
    document.title = meta.title + " | Frost Solutions" || "Frost Solutions";
  }
  next();
});

// Record page views for AWS RUM
router.afterEach((to, _from) => {
  window.awsRum.recordPageView(to.fullPath);
});

router.beforeResolve((to, from, next) => {
  // Check if its a setup-steps route, then update the store with the route params
  // We do this to simplify some of the logic in the components
  if (to.name === "setup-steps") {
    const setupStore = useSetupStore();
    setupStore.initStore({
      vendorId: to.params.id,
      deviceType: to.params.deviceType,
      step: parseInt(to.params.step, 10),
    });
  }
  next();
});

export default router;
