<template>
    <form @submit="handleSubmit" ref="form">
        <div class="flex-row flex-wrap space-between">
            <span>Details</span>
            <div class="form-group flex-row">
                <label class="subtitle" for="isReplacement">Is this a replacement?
                    <input type="checkbox" id="isReplacement" name="isReplacement" v-model="isReplacement" />
                </label>
            </div>
        </div>
        <div v-if="isInProvisioningGroup" class="bg-lighter p-2">
            <i class="fa fa-warning mr-1"></i>
            <span>The new device hasn't been added to your account. Please contact support at <a href="tel:312-767-9423"
                    class="bold">312-767-9423</a>, <span class="bold">Option 0</span> before going further</span>
        </div>
        <div v-if="isReplacement" class="bg-lighter p-2">
            <div v-if="!isInProvisioningGroup" class="form-group">
                <label class="text-mono">Existing Device</label>
                <v-select id="existing-device" class="rounded" :clearable="false" :components="{ OpenIndicator }"
                    :options="existingDevicesOptions" v-model="deviceToBeReplaced"
                    @input="handleExistingDeviceSelection" placeholder="Select the device you want to replace" />
            </div>
        </div>
        <div class="flex-row flex-wrap space-between">
            <div class="form-group">
                <label class="subtitle" for="location-name">Location Name</label>
                <input type="text" id="location-name" name="name" placeholder="Choose a useful name"
                    v-model="locationName" />
            </div>
            <div class="form-group">
                <label class="subtitle">Serial Number</label>
                <span class="text-mono">{{ props.id }}</span>
            </div>
        </div>
        <div v-if="!hiddenFields.includes('SurfaceType')" class="form-group">
            <span class="subtitle">Surface Type</span>
            <div class="checkbox-group">
                <label v-for="surfaceType in surfaceTypeOptions">
                    <input type="radio" name="surfaceType" :value="surfaceType.id" v-model="surfaceTypeID" />
                    {{ surfaceType.name }}</label>
            </div>
        </div>
        <div v-if="!hiddenFields.includes('LocationType')" class="form-group">
            <span class="subtitle">Location Type</span>
            <div class="checkbox-group">
                <label v-for="locationType in locationTypeOptions">
                    <input type="radio" name="locationType" :value="locationType.id" v-model="locationTypeID" />
                    {{ locationType.name }}</label>
            </div>
        </div>
        <form-map :position="position" @handlePositionUpdate="handlePositionUpdate" />
        <div class="flex-row">
            <div class="alert alert-warning" v-if="errorMessage">
                <i class="fa fa-exclamation-triangle"></i>
                <span>{{ errorMessage }}</span>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-secondary mr-2" @click="handleBack">
                <i class="fa fa-close"></i>
                Back</button>
            <button class="btn btn-primary" :disabled="disabled">
                <i class="fa fa-arrow-right"></i>
                Continue</button>
        </div>
    </form>
</template>
<script setup>
import { defineProps, ref, onMounted, computed, watch } from 'vue';

import FormMap from './FormMap.vue';
import API from '../../js/api';
import { useLocationsStore, useSetupStore } from '../../js/store';

const api = new API();
const setupStore = useSetupStore();
const locationsStore = useLocationsStore();

const props = defineProps({
    id: String,
    deviceType: String,
    step: Number,
});

const isReplacement = ref(false);
const isInProvisioningGroup = computed(() => {
    return setupStore.setupDevice?.Group?.Name === 'PROVISIONING_GROUP';
});
const existingDevices = ref([]);
const existingDevicesOptions = ref([]);
watch(isReplacement, (newValue) => {

    if (newValue === true && Object.keys(locationsStore.locations).length === 0) {
        const group = setupStore.setupDevice?.Group;
        const groupId = group?.ID;
        locationsStore.getLocationsByGroupID(groupId, true).then((response) => {
            existingDevices.value = response
            existingDevicesOptions.value = response.map((location) => {
                if (location.DeviceType === 'Snow Depth Sensor') {
                    return null
                }
                return {
                    value: location.ID,
                    label: `${location.Name} (${location.DeviceType})`,
                }
            }).filter((location) =>
                location !== null)

        });
    }
})

const deviceToBeReplaced = ref(null);

const handleExistingDeviceSelection = (value) => {
    const location = existingDevices.value.find((location) => location.ID === value.value);
    locationName.value = location.Name;
    position.value = {
        lat: location.Latitude,
        lng: location.Longitude,
    }
    locationTypeID.value = location.LocationTypeID;
    surfaceTypeID.value = location.SurfaceTypeID;
}

const form = ref(null);
const locationName = ref('');
const locationTypeID = ref(null);
const surfaceTypeID = ref(null);
const position = ref({
    lat: 41.8758108, lng: -87.6206127
})

const hiddenFields = computed(() => {
    return setupStore.hiddenFields;
});

const locationTypeOptions = ref([]);
const surfaceTypeOptions = ref([]);

const errorMessage = ref(null);

const disabled = computed(() => {
    if (isInProvisioningGroup.value) {
        return true;
    }
    if (hiddenFields.value.includes('SurfaceType') && hiddenFields.value.includes('LocationType')) {
        return !locationName.value;
    } else {
        return !locationName.value || !locationTypeID.value || !surfaceTypeID.value;
    }
});

function handlePositionUpdate(key, newPosition) {
    if (key === 'position') {
        position.value = newPosition;
        return;
    }
    const float = parseFloat(newPosition);
    position.value = {
        ...position.value,
        [key]: float,
    };
}

async function getDeviceId() {
    try {
        let id = setupStore.setupDevice.ID;
        if (!id) {
            await setupStore.getDeviceByVendorId(props.id);
            id = setupStore.setupDevice.ID;
        }
        return id;
    } catch (error) {
        console.error(error);
    }
}

async function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = {
        Name: locationName.value,
        Latitude: position.value.lat,
        Longitude: position.value.lng,
        DeviceType: props.deviceType,
    }
    if (!hiddenFields.value.includes('SurfaceType')) {
        data.SurfaceTypeID = formData.get('surfaceType')
    }
    if (!hiddenFields.value.includes('LocationType')) {
        data.LocationTypeID = formData.get('locationType')
    }
    try {
        // Only patch if the device is a replacement and they still have isReplacement selected
        if (isReplacement.value && deviceToBeReplaced.value) {
            const deviceToBeReplacedId = deviceToBeReplaced.value?.value;
            await setupStore.replaceDevice(deviceToBeReplacedId, props.id)
        }
        await setupStore.updateDevice(data);
        setupStore.goToNextStep();
    } catch (error) {
        console.error(error);
    }
}

async function getOptions() {
    try {
        const locationTypes = await api.getLocationTypes();
        locationTypeOptions.value = locationTypes.map((locationType) => {
            return {
                id: locationType.ID,
                name: locationType.Name,
            }
        });

    } catch (error) {
        console.error(error);
    }
    try {
        const surfaceTypes = await api.getSurfaceTypes();
        surfaceTypeOptions.value = surfaceTypes.map((surfaceType) => {
            return {
                id: surfaceType.ID,
                name: surfaceType.Name,
            }
        });
    } catch (error) {
        console.error(error);
    }

}
function handleBack() {
    setupStore.goToPreviousStep();
}

onMounted(async () => {
    await getOptions();
    await setupStore.getDeviceByVendorId(props.id);
    // Set ref values to values from the store
    locationName.value = setupStore.setupDevice.Name;
    if (setupStore.setupDevice.Latitude && setupStore.setupDevice.Longitude) {
        position.value = {
            lat: setupStore.setupDevice.Latitude,
            lng: setupStore.setupDevice.Longitude,
        }
    }
    if (setupStore.setupDevice.SurfaceTypeID) {
        surfaceTypeID.value = setupStore.setupDevice.SurfaceTypeID;
    }
    if (setupStore.setupDevice.LocationTypeID) {
        locationTypeID.value = setupStore.setupDevice.LocationTypeID;
    }
})

</script>